.reviews .reviews__container__header {
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	flex-direction: row;
	justify-content: flex-end;
	-ms-flex-line-pack: justify;
	align-content: space-between;
	text-align: right;
}

.reviews__container {
	margin-left: 20px;
	margin-right: 322px;
}

.reviews__container__no-reviews {
	width: 800px;
}

.reviews_buttons {
	display: flex;
	flex-wrap: nowrap;
	align-content: space-between;
	margin-left: 10px;
	margin-top: 20px;
}

.reviews__buttons-default--disabled {
	cursor: default;
	background-color: #ffffff;
	color: #676a6c !important;
	border-color: #d2d2d2;
}

.reviews__buttons-default--disabled:hover,
.reviews__buttons-default--disabled:focus,
.reviews__buttons-default--disabled:active,
.reviews__buttons-default--disabled.active,
.reviews__buttons-default--disabled:active:focus,
.reviews__buttons-default--disabled:active:hover,
.reviews__buttons-default--disabled.active:hover,
.reviews__buttons-default--disabled.active:focus {
	background-color: #ffffff;
	color: #676a6c !important;
	border-color: #d2d2d2;
}

.reviews__side--panel {
	width: 280px;
	margin-right: 18px;
	position: fixed;
	z-index: 1;
	top: 172px;
	right: 10px;
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 85%;
	padding-top: 5px;
	padding-bottom: 20px;
	padding-left: 10px;
	padding-right: 10px;
	background-color: white;
	border-radius: 15px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #e7eaec;
}
.reviews__side--panel-content {
	display: block;
}

.reviews__single-review {
	padding: 25px;
	margin-top: 25px;
	border-radius: 15px;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
	border: solid 1px #e7eaec;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.reviews__single-review:nth-child(2) {
	margin-top: 0 !important;
}

.reviews__single-review__header-right {
	float: right;
	display: flex;
	align-items: center;
}

.reviews__filter--container {
	display: none;
}

.reviews__propagate-reviews {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.reviews__propagate-reviews__site-logo {
	height: 40px;
	margin-right: 15px;
}

.reviews__heading {
	display: flex;
	flex-direction: row;
}

.reviews__single-review__content {
	flex-grow: 1;
}

@media screen and (max-width: 786px) {
	.reviews__side--panel {
		display: none;
	}
	.reviews__container {
		margin-right: 0px;
	}
	.reviews__filter--container {
		display: block;
	}
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.reviews_buttons {
		margin-right: 30px;
	}
}

@media (max-width: 767px) {
	.reviews__container__header {
		width: 100%;
		float: left;
		justify-content: space-between;
	}
}

.review__media-body {
	width: 100%;
}

.reviews__comment {
	margin-top: 16px;
	margin-bottom: 10px;
}

.reviews__media-heading {
	margin-top: 4px;
	margin-bottom: 3px;
	font-size: 18px;
}

.review-tag {
	margin-top: 10px;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
	justify-content: flex-start;
}

.reviews__single-review__header-right__show-on-widgets {
	margin-left: 10px;
}

.reviews__table_wrapper {
	width: 100%;
}

.reviews__media-heading__contact {
	font-size: 12px;
}

.review-date {
	font-size: 14px;
}

.reviews__media-heading__contact-name {
	font-weight: 800;
	font-size: 18px;
}

.review-tag__button {
	display: flex;
	align-content: center;
	border-radius: 5px;

	user-select: none;
}

.reviews__media-heading-inner {
	display: flex;
	flex-direction: row;
}

.reviews__media-heading__rating {
	margin-left: 15px;
}

.reviews__filter__title {
	font-size: 18px;
	font-weight: 600;
}

.reviews__side--panel-content__form-group {
}

.review__size--panel-content__reset {
	margin-top: 40px;
	display: flex;
	justify-content: flex-end;
}

.reviews__side--panel-content__needs-response__option {
	display: flex;
	flex-direction: row;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding-top: 23px;
	padding-left: 16px;
}

.reviews__side--panel-content__needs-response__option__checkbox {
	margin-right: 10px;
}

.reviews__side--panel-content__needs-response__option__label {
	font-weight: 600;
}

.reviews__single-review__header-right__show-on-widgets {
	margin-left: 5px;
}

.reviews__single-review__header-right-control {
	margin-left: 5px;
	margin-top: 2px;
}

.reviews__single-review__header-right-control:hover {
	cursor: pointer;
}

.review-reply {
	background-color: #eff6ff;
	padding: 20px;
	border-radius: 10px;

	flex-grow: 1;
}

.review-reply__top_section {
	display: flex;
	flex-direction: row;
	margin-bottom: 7px;
	align-items: center;
}

.review-reply__top_section__spacer {
	margin-left: auto;
}

.review-reply__top_section__controls_container {
	display: flex;
	align-items: center;
	flex-direction: row;
}

.review-reply__top_section__controls_container__control {
	margin-left: 10px;
	margin-top: 2px;
}

.review-reply__top_section__controls_container__control:hover {
	cursor: pointer;
}

.review-reply__container {
	margin-top: 20px;
	margin-bottom: 10px;

	display: flex;
	flex-direction: row;
	align-items: center;
}

.review-reply__arrow {
	margin-right: 23px;
}

.review-tag__button__icon {
	margin-right: 5px;
}
