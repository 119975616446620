.invite-users__search {
	width: 400px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.invite-users__search .mb-button {
	margin-top: 0;
	margin-left: 10px;
}

.invite-users__search .search-input {
	margin-top: 0;
	width: 100%;
	margin-right: 15px;
}

.invite-users__form {
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	font-size: 14px;
	padding-left: 15%;
	padding-right: 15%;
	padding-bottom: 150px;
}

.invite-users__form-input {
	width: 350px;
	max-width: 350px;
}

.invite-users__form-item:first-child {
	margin-top: 0px;
}

.invite-users__form-item label {
	margin-top: 10px;
}

.invite-users__form-item {
	width: 100%;
	margin-top: 30px;
	width: 500px;
	min-width: 350px;
	max-width: 500px;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	padding: 20px;
	font-size: 14px;
	background-color: #f6f6f9;
}

.invite-users__form-item--email {
	margin-bottom: 0px;
	font-weight: 600;
	font-size: 16px;
	display: flex;
	justify-content: space-between;
}

.invite-users__form-item--email svg {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.invite-users__form-item--email svg:hover {
	width: 20px;
	height: 20px;
	cursor: pointer;
	opacity: 0.6;
}

.invite-users__form-item--group {
	font-weight: 300;
}

.invite-users__form-save-btn {
	width: 500px;
	display: flex;
	justify-content: flex-end;
	margin-top: auto;
}
.invite-users__form-save-btn .mb-button {
	width: auto;
	margin-top: 30px;
}

.invite-users__feedback-list {
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	width: 500px;
	max-width: 500px;
}

.invite-users__feedback-list__item {
	display: flex;
	justify-content: space-between;
	padding-top: 7px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.invite-users__feedback__header {
	margin-top: 35px;
	text-align: center;
}

.invite-users__teamchat-channels {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.invite-users__teamchat-channels-row__action:hover {
	cursor: pointer;
}

.invite-users__teamchat-channels-row__name {
	padding-left: 29px;
	font-size: 14px;
}
