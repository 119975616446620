/* rwc == review-widget-config */

.rwc {
	display: flex;
	flex-flow: row wrap-reverse;
	align-items: flex-end;
	justify-content: flex-start;

	margin: 20px 30px;
	width: calc(100% - 60px);
	min-width: 400px;
}

.rwc__options {
	margin-right: 50px;
	max-width: 500px;
	min-width: 400px;
}

.rwc__options__field {
	font-size: 14px;
	height: 120px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	border-bottom: 1px solid #f5f5f5;
}

.rwc__options__field--no-border {
	border: none;
}

.rwc__options__field--tall {
	margin-top: 30px;
	height: 450px !important;
	align-items: flex-start;
}

.rwc__options__field--large {
	margin-top: 30px;
	height: 300px !important;
	align-items: flex-start;
}

.rwc__options__field--medium {
	margin-top: 30px;
	height: 150px !important;
	align-items: flex-start;
}

.wcc__options__field--no-border {
	border: none;
}

.rwc__options__field--positioning {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: auto;
	padding-top: 30px;
	padding-bottom: 30px;
}

.rwc__options__field__label {
	flex: 1;
	margin-right: 20px;
}

.rwc__options__field__label--center {
	width: 100%;
	text-align: center;
}

.rwc__options__field__label__title {
	font-weight: 900;
}

.rwc__options__field__label__title--large {
	font-size: 18px;
}

.rwc__options__field__label__description {
	margin-top: 5px;
}

.rwc__options__field__input {
	margin-left: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
}

.rwc__options__field__input__label {
	font-size: 11px;
	margin-top: 10px;
	margin-bottom: 2px;
	color: #7e7e7e;
}

.rwc__options__field__select {
	width: 170px;
}

.rwc__preview__update {
	margin-top: 10px;
	display: flex;
	flex-flow: row nowrap;
}

.rwc__options__field__bubble_icons_container {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.rwc__options__field__bubble_icon {
	margin-right: 7px;
	padding: 13px;
	border-radius: 90px;
	display: flex;
}

.rwc__options__field__bubble_icon:hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.rwc__options__field__bubble_icon--active {
	background-color: #f5f5f5;
}

.rwc__preview {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
	margin-right: 20px;
	min-width: 700px;
	position: -webkit-sticky;
	position: sticky;
	top: 30px;
}

.rwc__preview__core {
	background-color: rgb(244, 246, 249);
	width: 100%;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 15px 15px 15px;
	border-radius: 8px;
	/*pointer-events: none;*/
}

.rwc__preview__core__header {
	display: flex;
	align-items: center;
	font-weight: 500;
	font-size: 13px;
	color: rgb(135, 135, 135);
	margin-bottom: 15px;
}

.rwc__preview__core__header svg {
	padding-right: 5px;
}

.rwc__preview__iframe {
	height: 500px;

	display: flex;
	flex-direction: row;
	align-items: center;
	margin: auto;
}

.rwc__preview__iframe * {
	font-family: demandhub-montserrat-regular, sans-serif !important;
}

.rwc__preview__container__image {
	margin: auto;
	position: relative;
}
.rwc__preview__container__image > img {
	max-width: 100%;
	max-height: 220px;
}

.rwc__preview__container__image__rating {
	font-size: 16px;
	color: #333 !important;

	position: absolute;
	top: 68%;
	left: 50%;
	transform: translate(-50%, -40%);
}

@media (max-width: 1475px) {
	.rwc__preview {
		position: relative !important;
	}
}
