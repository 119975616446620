/* CUSTOMER MESSAGE */
.mb-message-customer {
    font-size: 15px;
    margin-bottom: 10px;
    max-width: 80%;
    min-width: 100px;
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.mb-message-customer--out {
    align-self: flex-end;
    margin-right: 30px;
}

.mb-message-customer--in {
    margin-left: 30px;
}

.mb-message-customer-box {
    border-radius: 8px;
    border: 2px solid #F6F6F9;
    background-color: #F6F6F9;
    padding: 8px;
    display: flex;
    flex-flow: column nowrap;
}

.mb-message-customer-box--secure-in {
    background-color: #f1e7fe !important;
    border-color:  #f1e7fe !important;
}


.mb-message-customer-box--secure-out {
    background-color: #9f5afd !important;
    border-color:  #9f5afd !important;
    color: white !important;
}

.mb-message-customer-box--out {
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
}

.mb-message-customer-box--unread {
    background-color: #c5ddf8;
}

.mb-message-box-customer--removed {
    font-style: italic;
}

.mb-message-customer-top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
}

.mb-message-customer-bottom {
    margin-top: 10px;
    font-size: 10px;
    align-self: flex-end;
    display: flex;
    align-items: center;
}

.mb-message-customer-bottom__read-receipt {
    padding-left: 4px;
    opacity: 0.5;
}

.mb-message-customer-bottom__read-receipt--read {
    opacity: 1;
}

.mb-message-customer-bottom__read-receipt__sent-icon {
    position: absolute;
    right: 3px;
}

.mb-message-customer-context {
    margin-left: auto;
    opacity: 0;
    align-self: baseline;
}

.mb-message-customer-event-context {
    opacity: 0;
    position: absolute;
    top: 9px;
    right: 10px;
}

.mb-message-customer:hover .mb-message-customer-context, .mb-message-event-divider-content--note:hover .mb-message-customer-event-context {
    opacity: 1;
    cursor: pointer;
}

.mb-message-customer-event-context p {
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 4px;
    border: 1px solid #595959;
}

.mb-message-name {
    font-size: 12px;
    font-weight: 700;
}

/* CONTEXT MENU */

.mb-message-context-menu {
    position: absolute;

    background-color: white;
    padding: 10px 0 10px 0;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    border-width: 0;
    border-radius: 3px;
    z-index: 1010;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.mb-message-context-menu--team {
    right: 50px;
    bottom: calc(50% - 15px);
}

.mb-message-context-menu--out {
    right: 10px;
    top: 30px;
}

.mb-message-context-menu--note {
    right: 10px;
    top: 30px;
}

.mb-message-context-menu--in {
    right: -100px;
    top: 30px;
}

.mb-message-context-menu-item {
    padding: 5px 10px 5px 10px;
    width: 150px;
}

.mb-message-context-menu-item:hover {
	cursor: pointer;
	background-color: rgba(96, 169, 255, 0.2);
	transition: background 0.2s, border 0.2s;
}

/* CONTENT LINK */

.mb-message-content-link {
    text-decoration: underline;
}

.mb-message-content-link--out {
    color: white;
}

.mb-message-content-blurb {
    margin-bottom: 0; 
    width: 100%; 
    word-break: break-word;
    white-space: pre-wrap;
}

.mb-message-customer__media {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;
}


@keyframes blink {50% { color: transparent }}
.mb-message__transcription-loader__dot { animation: 2000ms blink infinite }
.mb-message__transcription-loader__dot:nth-child(2) { animation-delay: 250ms }
.mb-message__transcription-loader__dot:nth-child(3) { animation-delay: 500ms }

.mb-message__transcription-loader__dot {
    line-height: 10px;
    color: #333333;
    font-size: 32px;
}