/* erc == embedded-reviews-config */

.erc {
	display: flex;
	flex-flow: row wrap-reverse;
	align-items: flex-end;
	justify-content: flex-start;

	margin: 20px 30px;
	width: calc(100% - 60px);
	min-width: 400px;
}

.erc__options {
	margin-right: 50px;
	max-width: 500px;
	min-width: 400px;
}

.erc__options__field {
	font-size: 14px;
	height: 120px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	border-bottom: 1px solid #f5f5f5;
}

.erc__options__field--tall {
	margin-top: 30px;
	height: 550px !important;
	align-items: flex-start;
}

.erc__options__field--large {
	margin-top: 30px;
	height: 300px !important;
	align-items: flex-start;
}

.erc__options__field--medium {
	margin-top: 30px;
	height: 150px !important;
	align-items: flex-start;
}

.erc__options__field__label {
	flex: 1;
	margin-right: 20px;
}

.erc__options__field__label__title {
	font-weight: 900;
}

.erc__options__field__label__description {
	margin-top: 5px;
}

.erc__options__field__input {
	margin-left: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
}

.erc__options__field__input__label {
	font-size: 11px;
	margin-top: 10px;
	margin-bottom: 2px;
	color: #7e7e7e;
}

.erc__options__save-actions {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
