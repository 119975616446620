/* eim edit-image-modal */
.eim__crop {
	width: 100%;
	height: 100%;
	max-width: 600px;
	max-height: 600px;
}

.eim__crop__img {
	object-fit: contain;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.eim__actions {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	margin-bottom: 15px;
	background-color: #f4f4f4 !important;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}
