@font-face {
    font-family: AvenirNext;
    src: url("./AvenirLTStd-Light.otf") format("opentype");
}

@font-face {
    font-family: AvenirNext;
    font-style: italic;
    src: url("./AvenirLTStd-LightOblique.otf") format("opentype");
}

@font-face {
    font-family: AvenirNext;
    font-weight: bold;
    src: url("./AvenirNextLTPro-Bold.otf") format("opentype");
}
