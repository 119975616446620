.mb-more-options-list {
    position: absolute;
    top: 100px;
    right: 10px;
    width: 300px;
    height: auto;
    max-height: 600px;
    background-color: white;
    margin: 10px 0 20px 0px;
    border-radius: 5px;
    padding: 0;
    border: 1px solid #E6E6E6;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: column nowrap;
    z-index: 999999;
}

.mb-more-options-item {
    font-size: 16px;
    padding: 15px;
    border-bottom: 1px solid #F1F1F1;
    display: flex;
    flex-direction: row;
}

.mb-more-options-item:hover {
	background-color: rgba(96, 169, 255, 0.2);
	transition: background 0.2s, border 0.2s;
}

.mb-more-options-item__icon {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 5px;
}

.mb-more-options-item__icon svg {
    width: 20px;
    height: 20px;
}

.mb-more-options-item__text__container {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 15px;
    flex: 1;
    cursor: auto;
}

.mb-more-options-item__text {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.mb-more-options-item__text:hover {
    opacity: 0.8;
}

.mb-more-options-item__text--not-clickable:hover {
    opacity: 1 !important;
    cursor: default;
}

.mb-more-options-item__text__name {
    font-size: 15px;
    font-weight: 300;
}

.mb-more-options-item__text__name--not-clickable:hover {
    opacity: 1 !important;
    cursor: default;
}

.mb-more-options-item__text__name--cf {
    display: flex;
    justify-content: space-between;
}

.mb-more-options-item__text__name__checkbox {
    margin-left: auto;
}

.mb-more-options-item__text__description {
    font-size: 12px;
    color: #9D9E9D;
}

.mb-more-options-item__icon:hover  {
    opacity: 0.8;
    cursor: pointer;
}

.mb-more-options-item__icon--not-clickable:hover {
    opacity: 1 !important;
    cursor: default !important;
}

.mb-more-options__add-note {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.mb-more-options__add-note__buttons {
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.mb-more-options__add-note__icon {
    padding: 0px 4px;
    margin: 0 3px;
    height: 20px;
    cursor: pointer;
}

.mb-more-options__add-note__icon svg {
    width: 20px;
    height: 20px;
}

.mb-more-options__add-note__icon--disabled {
    opacity: 0.55;
}

.mb-more-options__add-note__input {
    width: 100%;
    margin: 7px 0;
    border-radius: 8px;
    border: 1px solid #E6E6E6;
    padding: 10px;
    font-size: 18px;
    resize: none;
    min-height: 24px;
    height: 40px;
    max-height: 105px;
    overflow: hidden;
    overflow-wrap: break-word;
}

.mb-more-options__add-note__input--disabled {
    background-color: #FAFAFA;
    color: #757575;
}

.mb-more-options__add-note__loader {
    margin-top: 3px;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #3498db;
    width: 17px;
    height: 17px;
    -webkit-animation: addNoteLoaderSpin 1.5s linear infinite; /* Safari */
    animation: addNoteLoaderSpin 1.5s linear infinite;
}

/* Safari */
@-webkit-keyframes addNoteLoaderSpin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes addNoteLoaderSpin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.mb-more-options__move-inbox {
    margin-top: 10px;
}

.mb-more-options__move-inbox__text {
    font-size: 12px;
    color: #9D9E9D;
}

.mb-more-options__move-inbox__select {
    z-index: 1000000000;
    font-size: 16px;
}