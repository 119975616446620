.dh-search {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;

	background-color: #f6f6f9;
	height: 35px;
	min-width: 280px;
	border-radius: 50px;
	padding-left: 20px;
	padding-right: 20px;
	margin-right: auto;
}

.dh-search__input {
	padding-top: 5px;
	font-size: 16px;
	border: none;
	background-color: #f6f6f9;
	flex: 1;
}

.dh-search__close {
	padding-top: 5px;
	margin-left: auto;
}

.dh-search--full-width {
	width: 100%;
}
