.Spinners__circle-fade {
	width: 1em;
	height: 1em;
	border-radius: 50%;
	position: relative;
	text-indent: -9999em;
	-webkit-animation: circleFadeAnimate 1.1s infinite ease;
	animation: circleFadeAnimate 1.1s infinite ease;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
@-webkit-keyframes circleFadeAnimate {
	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em #4ea6ef, 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2),
			-2.6em 0em 0 0em rgba(78, 166, 239, 0.5), -1.8em -1.8em 0 0em rgba(78, 166, 239, 0.7);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.7), 1.8em -1.8em 0 0em #4ea6ef, 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2),
			-2.6em 0em 0 0em rgba(78, 166, 239, 0.2), -1.8em -1.8em 0 0em rgba(78, 166, 239, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.5), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.7), 2.5em 0em 0 0em #4ea6ef,
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2),
			-2.6em 0em 0 0em rgba(78, 166, 239, 0.2), -1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.5), 2.5em 0em 0 0em rgba(78, 166, 239, 0.7),
			1.75em 1.75em 0 0em #4ea6ef, 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2), -2.6em 0em 0 0em rgba(78, 166, 239, 0.2),
			-1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.5),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.7), 0em 2.5em 0 0em #4ea6ef, -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2), -2.6em 0em 0 0em rgba(78, 166, 239, 0.2),
			-1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.5), 0em 2.5em 0 0em rgba(78, 166, 239, 0.7), -1.8em 1.8em 0 0em #4ea6ef, -2.6em 0em 0 0em rgba(78, 166, 239, 0.2),
			-1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.5), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.7), -2.6em 0em 0 0em #4ea6ef,
			-1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.5),
			-2.6em 0em 0 0em rgba(78, 166, 239, 0.7), -1.8em -1.8em 0 0em #4ea6ef;
	}
}

@keyframes circleFadeAnimate {
	0%,
	100% {
		box-shadow: 0em -2.6em 0em 0em #4ea6ef, 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2),
			-2.6em 0em 0 0em rgba(78, 166, 239, 0.5), -1.8em -1.8em 0 0em rgba(78, 166, 239, 0.7);
	}
	12.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.7), 1.8em -1.8em 0 0em #4ea6ef, 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2),
			-2.6em 0em 0 0em rgba(78, 166, 239, 0.2), -1.8em -1.8em 0 0em rgba(78, 166, 239, 0.5);
	}
	25% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.5), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.7), 2.5em 0em 0 0em #4ea6ef,
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2),
			-2.6em 0em 0 0em rgba(78, 166, 239, 0.2), -1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	37.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.5), 2.5em 0em 0 0em rgba(78, 166, 239, 0.7),
			1.75em 1.75em 0 0em #4ea6ef, 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2), -2.6em 0em 0 0em rgba(78, 166, 239, 0.2),
			-1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	50% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.5),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.7), 0em 2.5em 0 0em #4ea6ef, -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.2), -2.6em 0em 0 0em rgba(78, 166, 239, 0.2),
			-1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	62.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.5), 0em 2.5em 0 0em rgba(78, 166, 239, 0.7), -1.8em 1.8em 0 0em #4ea6ef, -2.6em 0em 0 0em rgba(78, 166, 239, 0.2),
			-1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	75% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.5), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.7), -2.6em 0em 0 0em #4ea6ef,
			-1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2);
	}
	87.5% {
		box-shadow: 0em -2.6em 0em 0em rgba(78, 166, 239, 0.2), 1.8em -1.8em 0 0em rgba(78, 166, 239, 0.2), 2.5em 0em 0 0em rgba(78, 166, 239, 0.2),
			1.75em 1.75em 0 0em rgba(78, 166, 239, 0.2), 0em 2.5em 0 0em rgba(78, 166, 239, 0.2), -1.8em 1.8em 0 0em rgba(78, 166, 239, 0.5),
			-2.6em 0em 0 0em rgba(78, 166, 239, 0.7), -1.8em -1.8em 0 0em #4ea6ef;
	}
}

.Spinners__bar-loader,
.Spinners__bar-loader:before,
.Spinners__bar-loader:after {
	background: #4ea6ef;
	-webkit-animation: barLoaderAnimation 1s infinite ease-in-out;
	animation: barLoaderAnimate 1s infinite ease-in-out;
	width: 1em;
	height: 4em;
}
.Spinners__bar-loader {
	color: #4ea6ef;
	text-indent: -9999em;
	position: relative;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.Spinners__bar-loader:before,
.Spinners__bar-loader:after {
	position: absolute;
	top: 0;
	content: "";
}
.Spinners__bar-loader:before {
	left: -1.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.Spinners__bar-loader:after {
	left: 1.5em;
}
@keyframes barLoaderAnimate {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}
@keyframes barLoaderAnimate {
	0%,
	80%,
	100% {
		box-shadow: 0 0;
		height: 4em;
	}
	40% {
		box-shadow: 0 -2em;
		height: 5em;
	}
}

.Spinners__circle {
	text-indent: -9999em;
	width: 11em;
	height: 11em;
	border-radius: 50%;
	background: #4ea6ef;
	background: -moz-linear-gradient(left, #4ea6ef 10%, rgba(78, 166, 239, 0) 42%);
	background: -webkit-linear-gradient(left, #4ea6ef 10%, rgba(78, 166, 239, 0) 42%);
	background: -o-linear-gradient(left, #4ea6ef 10%, rgba(78, 166, 239, 0) 42%);
	background: -ms-linear-gradient(left, #4ea6ef 10%, rgba(78, 166, 239, 0) 42%);
	background: linear-gradient(to right, #4ea6ef 10%, rgba(78, 166, 239, 0) 42%);
	position: relative;
	-webkit-animation: circleAnimate 1.4s infinite linear;
	animation: circleAnimate 1.4s infinite linear;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
}
.Spinners__circle:before {
	width: 50%;
	height: 50%;
	background: #4ea6ef;
	border-radius: 100% 0 0 0;
	position: absolute;
	top: 0;
	left: 0;
	content: "";
}
.Spinners__circle:after {
	background: #f9fdfc;
	width: 75%;
	height: 75%;
	border-radius: 50%;
	content: "";
	margin: auto;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}
@-webkit-keyframes circleAnimate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes circleAnimate {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

.Spinners__line-fade,
.Spinners__line-fade:before,
.Spinners__line-fade:after {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: lineFadeAnimate 1.8s infinite ease-in-out;
	animation: lineFadeAnimate 1.8s infinite ease-in-out;
}
.Spinners__line-fade {
	color: #4ea6ef;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.Spinners__line-fade:before,
.Spinners__line-fade:after {
	content: "";
	position: absolute;
	top: 0;
}
.loadSpinners__line-fadeer:before {
	left: -3.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.Spinners__line-fade:after {
	left: 3.5em;
}
@-webkit-keyframes lineFadeAnimate {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
@keyframes lineFadeAnimate {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}

.Spinners__3circle-fade,
.Spinners__3circle-fade:before,
.Spinners__3circle-fade:after {
	border-radius: 50%;
	width: 2.5em;
	height: 2.5em;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation: tCircleFadeAnimate 1.8s infinite ease-in-out;
	animation: tCircleFadeAnimate 1.8s infinite ease-in-out;
}
.Spinners__3circle-fade {
	color: #60a9ff;
	position: relative;
	text-indent: -9999em;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.Spinners__3circle-fade:before,
.Spinners__3circle-fade:after {
	content: "";
	position: absolute;
	top: 0;
}
.Spinners__3circle-fade:before {
	left: -3.5em;
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}
.Spinners__3circle-fade:after {
	left: 3.5em;
}
@-webkit-keyframes tCircleFadeAnimate {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}
@keyframes tCircleFadeAnimate {
	0%,
	80%,
	100% {
		box-shadow: 0 2.5em 0 -1.3em;
	}
	40% {
		box-shadow: 0 2.5em 0 0;
	}
}

.Spinners__tail-fade {
	color: #4ea6ef;
	text-indent: -9999em;
	overflow: hidden;
	width: 1em;
	height: 1em;
	border-radius: 50%;
	position: relative;
	-webkit-transform: translateZ(0);
	-ms-transform: translateZ(0);
	transform: translateZ(0);
	-webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
	animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@keyframes load6 {
	0% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	5%,
	95% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
	10%,
	59% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
	}
	20% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
	}
	38% {
		box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
	}
	100% {
		box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
	}
}
@-webkit-keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
@keyframes round {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}
