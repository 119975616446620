.booking-requests__notes_tooltip:before {
    width: 400px;
    height: 96px;
    white-space: pre-wrap;
}

.booking-requests__notes_tooltip:hover:before{
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 5 !important;
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical !important;
    
}

.booking-requests__notes_icon {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

/* brsc-modal = booking-request-status-change-modal */
.brsc-modal__checkbox {
    position: relative;
    top: -5px
}