.new-inbox-modal {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.new-inbox-modal__actions {
	margin-top: 20px;
	align-self: flex-end;
}
