.dh-nav {
	padding-top: 5px;
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	background-color: #333;
	overflow: auto;

	min-width: 60px;
	max-width: 240px;

	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;

	flex-shrink: 0;
	z-index: 1900;

	height: 100%;
}

.dh-nav::-webkit-scrollbar {
	display: none;
}

.dh-nav--hide {
	display: none !important;
}

.dh-nav__collapse {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	min-height: 40px;
	width: calc(100% - 20px);
	margin: 5px 10px;
	border-radius: 5px;
	border-left: 2px;
	color: rgba(255, 255, 255, 0.5);
}

.dh-nav__collapse:hover {
	color: white;
	background-color: rgba(255, 255, 255, 0.2);
	cursor: pointer;
	transition: background 0.2s, color 0.2s;
}

.dh-nav__item {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	z-index: 1900;
}

.dh-nav__item__container {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	height: 40px;
	width: calc(100% - 20px);
	margin: 5px 10px;
	border-radius: 5px;
	color: rgba(255, 255, 255, 0.5);

	position: relative;
}

.dh-nav__item__container--active {
	box-shadow: 0 0px 10px rgb(0, 0, 0, 0.2);
	background-color: white;
	color: #333;
}

.dh-nav__item__sub {
	width: 100%;
	padding-left: 15px;
	overflow: hidden;
}

.dh-nav__item__container:hover {
	color: white;
	background-color: rgba(255, 255, 255, 0.2);
	cursor: pointer;
	transition: background 0.2s, color 0.2s;
}

.dh-nav__item__container--new {
	box-shadow: 0 0px 10px rgb(255, 255, 255, 0.4);
	color: white;
}

.dh-nav__item__container__icon {
	margin-left: 12px;
	margin-top: 5px;
}

.dh-nav__item__container__title {
	margin-top: 2px;
	font-size: 16px;
	margin-left: 10px;
	text-overflow: ellipsis;
	overflow: hidden;

	white-space: nowrap;
}

.dh-nav__item__container__spacer {
	margin-left: auto;
}

.dh-nav__item__container__new {
	font-size: 10px;
	border-radius: 4px;
	padding: 3px 5px 1px 5px;
	color: white;
	background-color: #60a9ff;
	margin-right: 20px;
	font-weight: 900;
	box-shadow: 0 0px 10px rgb(0, 0, 0, 0.2);
}

.dh-nav__item__container__red-dot {
	background-color: #ec4758;
	border-radius: 50px;
	width: 8px;
	height: 8px;
	margin-right: 10px;
}

.dh-nav__item__container__red-dot--collapsed {
	right: -3px;
	top: 8px;
	position: absolute;
}

.dh-nav__item__container__chevron {
	margin-top: 5px;
	margin-right: 10px;
}

.dh-nav__item--relative {
	/* position: relative; */
}

.dh-nav__item__sub__collapsed {
	padding-top: 5px;
	padding-bottom: 5px;
	left: 60px;
	bottom: 400;
	position: absolute;
	width: 220px;
	z-index: 1900;
	background-color: #333;

	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.dh-nav__logo {
	width: calc(100% - 100px);
	align-self: center;
}

.dh-nav__collapsed-logo {
	width: calc(100% - 10px);
	align-self: center;
	max-height: 30px;
}

.dh-nav__collapse__header {
	width: 100%;

	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.dh-nav__collapse__header__logo {
	margin-left: 10px;
	width: 120px;
	max-height: 30px;
}

.dh-nav__collapse__header__close {
	margin-top: 6px;
	margin-right: 7px;
	margin-left: auto;
	justify-self: flex-end;
}
