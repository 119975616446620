.user-invite__container {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.user-invite {
	padding: 16px 25px 25px;
	border-radius: 20px;
	width: 400px;
	margin: 8% auto 50px;

	background-color: #fff;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.user-invite__inputs {
	width: 300px;
	text-align: left;
}

.user-invite__header__sub-header {
	width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 18px;
	line-height: 27px;
}

.user-invite__header__sub-header__text {
	padding: 0 10px;
}

.user-invite__header__sub-header__img {
	width: 40px !important;
	height: 40px !important;
}

.user-invite__header__img {
	margin: auto;
	display: block;
	width: 75px !important;
	height: 75px !important;
}

.user-invite__options {
	margin-top: 15px;
	width: 315px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.user-invite__options__option {
	height: 34px;
	min-height: 34px;
	width: 230px;
	margin-top: 15px;
}

.user-invite__options__option.mb-button {
	display: flex;
	align-items: center;
	align-self: center;
	margin-left: 0;
}

.user-invite__options__option .fa {
	font-size: 16px;
	margin-left: 5px;
	margin-right: 12px;
	text-align: left;
	width: 16px;
}

.user-invite__email {
	color: #60a9ff;
	text-align: center;
	font-size: 14px;
}

.user-invite__options__option--email .fa {
	position: relative;
	top: -2px;
	font-size: 15px;
}

.user-invite__options__option:first-child {
	margin-top: 0;
}

.user-invite__options__option__text {
	height: 16px;
}

.user-invite__options__or {
	margin-top: 15px;
	width: 100%;
	height: 10px;
	display: flex;
	align-items: center;
	text-align: center;
	font-size: 14px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.user-invite__options__or::after,
.user-invite__options__or::before {
	content: "";
	border-top: 1px solid #e0e0e0;
	margin: 0 5px;
	flex: 1;
}

/* Space on left and right sides of text */
.user-invite__options__or:not(:empty)::before {
	margin-right: 1em;
}

.user-invite__options__or:not(:empty)::after {
	margin-left: 1em;
}

.dh__red-text {
	color: rgba(255, 99, 132, 1);
}

.user-invite__create-btn {
	width: 300px;
	display: flex;
	justify-content: flex-end;
}

.user-invite__create-btn .mb-button {
	width: auto;
	margin-left: auto;
}

.user-invite__create-btn .mb-button span {
	display: flex;
	align-items: center;
}

.user-invite__loading {
	width: 400px;
	height: 123px;
	padding-top: 25px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.user-invite__loading .Common__spinnerdiv--center {
	margin-top: 0px;
}

.user-invite__img {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.user-invite__img div {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.user-invite__img img {
	width: 60%;
	margin: auto;
}

@media only screen and (max-height: 1000px) {
	.user-invite {
		margin-top: 50px;
	}
}

@media only screen and (max-height: 900px) {
	.user-invite {
		margin-top: 40px;
	}
}

@media only screen and (max-width: 420px) {
	.user-invite {
		width: 90%;
	}

	.user-invite__header__sub-header {
		font-size: 16px;
	}

	.user-invite__header__sub-header__text {
		padding: 0;
	}

	.user-invite__inputs,
	.user-invite__create-btn,
	.user-invite__header,
	.user-invite__header__sub-header {
		width: 100%;
	}
}

@media only screen and (max-height: 390px) {
	.user-invite {
		margin-top: 15px;
	}
}

@media only screen and (max-width: 380px) {
	.user-invite {
		width: 85%;
		padding-left: 15px;
		padding-right: 15px;
	}
	.user-invite__options {
		width: 100%;
		max-width: 230px;
	}

	.user-invite__options__option__text {
		height: auto;
	}

	.user-invite__options__option {
		width: 100%;
		max-width: 230px;
		height: auto;
		min-height: 34px;
	}
}
