.manage-user {
	margin-top: 20px;
	margin-left: 40px;
	margin-bottom: 20px;

	display: flex;
	flex-direction: column;
	font-size: 14px;
	max-width: 500px;
}

.manage-user-page {
	padding: 20px 40px 40px;
	width: 100%;
}

.manager-user__locations {
	display: flex;
	flex-direction: column;
}

.manage-user__actions {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: flex-end;
}

.manage-user-spinner {
	margin-top: 100px;
	margin-left: 110px;
}

.manage-user__row {
	margin-bottom: 15px;
}

.manage-user__row-spacer {
	font-size: 0;
	height: 10px;
	line-height: 0;
}

.manage-user__label {
	flex: 1 1;
	margin-right: 20px;
}

.manage-user__label__title {
	font-weight: 900;
}

.manage-user__label__description {
	margin-top: 5px;
}

.manage-user__teamchat-channels {
	padding-top: 10px;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.manage-user__teamchat-channels-row__action:hover {
	cursor: pointer;
}

.manage-user__teamchat-channels-row__name {
	padding-left: 29px;
	font-size: 14px;
}
