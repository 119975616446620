.mb-list__bulk-controls-section__container{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px;

    background-color: #e9f2ff;
}

.mb-list__bulk-controls-section__title{
    font-size: 16px;
    font-weight: 800;
}

.mb-list__bulk-controls-section__control-item {
    width: 230px;
    margin-right: 20px;
    position: relative;
}

.mb-list__bulk-controls-section__control-item__label {
    margin-top: 20px;
    margin-bottom: 5px;
    margin-left: 3px;
}

.mb-list__bulk-controls-section__control-item__select {
    width: 230px;
}


.mb-list__bulk-controls-section__summary{
    padding-top: 20px;
    padding-bottom: 10px;
    font-size: 12px;
}

.mb-list__bulk-controls-section__final_controls{
    padding-top: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}

.mb-list__bulk-controls-section__subtitle{
    font-size: 14px;
    font-weight: 500;
}

.mb-list__bulk-controls-section__top{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.mb-list__bulk-controls-section__close{
    align-self: center;
}

.mb-list__bulk-controls-section__close:hover{
    cursor: pointer;
}
