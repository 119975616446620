.dh-mts {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	padding: 4px 0 0 0 !important;
	margin-right: 10px;
}

.dh-mts__icon:hover {
	opacity: 0.8;
	cursor: pointer;
}
