.BookingRequest__date--input > div {
	width: 100%;
}
.BookingRequest__date--input .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
	background-color: #f0f8ff !important;
	color: rgb(0, 95, 204);
}
.BookingRequest__date--input .DayPicker-Day {
	border-radius: 0 !important;
}
.BookingRequest__date--input input {
	background-image: none;
	border-width: 1px;
	border-style: solid;
	background-color: hsl(0, 0%, 100%);
	border-color: hsl(0, 0%, 80%);
	color: inherit;
	display: block !important;
	height: 38px;
	padding: 0px 10px;
	transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
	width: 100%;
	font-family: demandhub-montserrat-regular, sans-serif !important;
	font-size: 16px;
	box-sizing: border-box;
}
.BookingRequest__date--input input:focus {
	border-color: #1ab394;
	outline-color: #1ab394;
	outline-width: 1px;
}
.BookingRequest__refresh {
	margin-left: 10px;
	cursor: pointer;
	user-select: none;
	transform: translate(0px, 2px);
	position: absolute;
}

/* send-booking-request-modal = sbrm */
.sbrm {
	min-width: 375px;
	width: 375px;
	position: relative;
}

.sbrm__loading {
	width: 100%;
}

.sbrm__name {
	font-size: 15px;
	text-align: center;
	position: relative;
	display: block;
}

.sbrm__name__icon {
	margin-left: 8px;
}
.sbrm__name__icon svg {
	position: relative;
	top: 2px;
}

.sbrm__send-button {
	margin-top: 15px;
	width: 80%;
	text-align: center;
}

.sbrm__last-request {
	text-align: center;
	/* font-size: 11px;  */
}
