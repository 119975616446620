.attachment {
	z-index: 1020;
	height: 200px;
	width: 250px;
	position: relative;
	background-color: white;
	margin: 0 15px 0 0;
	border-radius: 8px;
	overflow-y: none;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-flow: column nowrap;
	overflow: hidden;
}

.attachment--loading {
	display: flex;
	justify-content: center;
	align-items: center;
}

.attachment__overlay {
	width: 250px;
	height: 200px;
	position: absolute;
	opacity: 0;
	background-color: black;
	color: white;
	padding: 10px;
	border-radius: 8px;

	display: flex;
	flex-flow: column;
	align-items: flex-end;
	justify-content: flex-start;
	text-align: center;
	font-size: 16px;
}

.attachment__overlay__actions {
	display: flex;
	align-items: center;
	justify-content: center;
}

.attachment__overlay__actions .dh-action:last-child {
	margin-right: 0;
}

.attachment:hover > .attachment__overlay {
	transition: 0.2s;
	opacity: 0.7;
	cursor: pointer;
}

.attachment__file {
	padding: 25px 10px 10px 10px;
	font-size: 14px;
	width: 250px;
	height: 200px;

	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: flex-start;
	text-align: center;
}

.attachment__file__text {
	display: flex;
	justify-content: center;
	width: 100%;
	margin-top: auto;
	font-size: 12px;
}

.attachment__file__text__name {
	word-break: break-all;
	overflow: hidden;
	max-width: 15ch;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.attachment__img {
	height: 100%;
	width: 100%;
	object-fit: cover;

	overflow: hidden;
	border-radius: 8px;
}
