.mb-list {
    width: 400px;
    border-right: 1px solid #F1F1F1;
    display: flex;
    flex-flow: column nowrap;
}

.mb-list--hide {
    display: none;
}

.mb-list-header-container {
    padding-left: 30px;
    min-height: 70px;
    border-bottom: 1px solid #F1F1F1;
    height: 70px;
}

.mb-list-header {
    font-size: 20px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
}

.mb-list-search {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%;
}

.mb-list-search-input {
    flex: 1;
    font-size: 18px;
    border: none;
}

.mb-list-option {
    margin-right: 15px;
}

.mb-list-option--active {
    color: #60A9FF;
    text-decoration: underline;
}

.mb-list-option:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-list-icon {
    margin-right: 20px;
}

.mb-list-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-list-controls {
    margin-left: auto;
    display: flex;
}

.mb-list-icon--disabled {
    color: #adadad;
    cursor: default;
}

.mb-list-icon--disabled:hover {
    opacity: 1 !important;
    color: #adadad !important;
    cursor: auto !important;
}

.mb-list-conversations {
    flex-grow: 1;
    overflow-y: auto;
}

.mb-list-no-conversations {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 60px;
}

.mb-list-no-conversations-label {
    margin-top: 10px;
    font-size: 18px;
    text-align: center;
}

.mb-list-loader {
    font-size: 18px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    margin-top: 60px;
}

.mb-list-load-more {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;
}   

.mb-list-header:hover .mb-list-header__filter {
    width: 22px;
}

.mb-list-header__filter {
    transition: width 0.075s ease-in-out;
    width: 0px;
    margin-right: 12px;
    overflow: hidden;

    padding: 0px;
}

.mb-list-header__filter:hover {
    cursor: pointer;
}

.mb-list-header__filter--force-width {
    width: 22px !important;
}

.mb-convo__checkbox-container{
    margin-top: 2px;
}

.mb-convo__checkbox {
	margin-right: 10px;
}

.mb-list-icon--right-most {
    margin-right: 30px;
}