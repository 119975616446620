.media-grid-item {
	min-width: 33.3333333%;
	max-width: 33.3333333%;
	flex: 1 1 33.3333333%;
	padding-left: 5px;
	padding-right: 5px;
	margin-bottom: 10px;
}

.media-grid-item__caption {
	display: flex;
	flex-direction: column;
	background: #f4f4f4;
	padding: 15px;

	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

.media-grid-item__caption__title {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.media-grid-item__caption__date {
	font-size: 9px;
	font-style: italic;
	opacity: 0.7;
	margin-top: 2px;
}

.media-grid-item__content {
	position: relative;

	height: 150px;
	max-height: 150px;

	background: #e5e5e5;

	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.media-grid-item__content img {
	display: block;
	max-width: 100%;
	cursor: pointer;

	object-fit: contain;
	width: 100%;
	height: 100%;

	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.media-grid-item video {
	max-height: 150px;
	min-height: 150px;
	background: #f4f4f4;

	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}
