.customer-media-grid {
    padding-left: 20px;
    padding-right: 20px;
    max-height: 470px;
    overflow: scroll;
    width: 100%;
}

.customer-media-grid__no-media-image {
    overflow: hidden;
}

.customer-media-grid__no-media-image img{
    margin-top: 100px;
    max-width: 250px;
    margin-left: auto;
    display: block;
    margin-right: auto;
}

.customer-media-grid__load-more{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    margin-top: 15px;
}

.customer-media-grid__loading{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}