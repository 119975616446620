.dh-variables-dropdown-container {
	position: absolute;
	z-index: 1003;
	height: 200px;
	max-height: 200px;
	min-width: 280px;
	width: 280px;
	max-width: 280px;
	background-color: white;
	margin: 10px 20px 59px 30px;
	border-radius: 5px;
	padding: 0px;
	overflow-y: auto;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	top: 140px;
	left: -10px;
}

.dh-variables-dropdown--collapsed {
	width: calc(100% - 345px);
}

.dh-variables-dropdown--expanded {
	width: calc(100% - 870px);
}

.dh-variables-dropdown-search-box {
	padding-top: 0px;
	padding-bottom: 0px;
}

.dh-variables-dropdown-search-box-container {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	margin: 10px 10px 10px 10px;
	border-radius: 50px;
	background-color: #f1f2f6;
	border-width: 0px;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 20px;
	padding-right: 20px;
}
.dh-variables-dropdown-search-box-input {
	background-color: #f1f2f6;
	border-width: 0px;
	outline: none;
	font-size: 13px;
	line-height: 24px;
}

.dh-variables-dropdown-container-header {
	padding: 8px 15px;
}

.dh-variables-dropdown-content {
	display: flex;
	flex-direction: column;
}

.dh-variables-dropdown-content > .dh-variables-dropdown-item:first-child {
	border: none;
}

.dh-variables-dropdown-item {
	border-top: 1px solid rgba(0, 0, 0, 0.05);
	padding: 7px 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	cursor: pointer;
}

.dh-variables-dropdown-item:hover {
	background-color: #f6f6f9;
}

.dh-variables-dropdown-item > div {
	padding: 0px 5px;
}

.dh-variables-dropdown-item--selected {
	background-color: #f6f6f9;
}
.dh-variables-dropdown-item--selected:hover {
	background-color: #ededff !important;
}
.dh-variables-dropdown-messenger-search-close {
	margin-left: auto;
	margin-top: 2px;
	color: #8c9092;
	cursor: pointer;
	margin-bottom: 3px;
	height: 18px;
}
