.Templates__spinnerdiv--center {
	margin: auto;
	position: absolute;
	display: inline;
	transform: translate(20%, -50%);
}
.locations__buttons--header-align {
	margin-top: 19px;
	text-align: right;
}

.locations__sms-numbers-button {
	display: inline-block;
	padding: 1px 7px 2px;
	margin: 4px 4px 0 4px;
}

.locations__sms-numbers-button__icon {
	position: relative;
	top: 3px;
}

.locations__sms-numbers-label {
	display: inline-block;
	margin: 0 4px 0 4px;
}

.locations__new-line {
	display: none;
}

.locations_assignable-numbers .select__control {
	height: 35px;
	min-height: 35px;
}

.locations__add-new-number {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: baseline;
}

.locations__add-new-number span,
.locations__add-new-number input {
	margin: 0px 10px;
}

.locations__add-new-number span:first-child,
.locations__add-new-number input:first-child {
	margin: 0px 10px 0 0;
}

.locations__popover-buttons {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
}

.locations__note-comment {
	margin: 0px 10px;
	position: absolute;
}

.locations__note-comment span {
	display: inline-block;
	max-width: 225px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

#confirm-popover {
	max-width: 250px;
	width: 250;
}

#confirm-popover textarea {
	resize: none;
	width: 100%;
	font-size: 12px;
}

#confirm-popover p {
	font-size: 12px;
}

.locations__new-line {
	display: block;
}

.locations__sms-numbers-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: wrap;
	align-items: center;
	margin-top: 10px;
}

#notes-modal-body {
	height: 280px;
	overflow: auto;
}

#new_number_api {
	display: inline;
	width: auto;
}
#summary-table {
	margin: auto;
	width: 70%;
	table-layout: fixed;
	word-wrap: break-word;
}

#summary-table tr td:first-child {
	font-weight: 900;
	width: 50%;
	min-width: 50%;
	max-width: 50%;
	text-align: right;
}

.locations__deactivate-on {
	display: flex;
}

.locations__deactivate-on__clear-button {
	margin-left: 10px;
}

.locations__warning {
	color: #856404;
	background-color: #fff3cd;
	padding: 15px;
	border-radius: 8px;
	margin: 10px auto;
	width: 100%;
}

.locations-page {
	width: 100%;
	padding: 20px 40px;
}

@media only screen and (max-width: 1100px) {
	.locations__numbers-list {
		display: inline-block;
		width: 100%;
		text-align: left !important;
	}

	.locations__sms-numbers-button {
		padding: 4px 8px 4px;
		margin: 4px 8px 0 8px;
	}

	.locations__note-comment span {
		display: inline-block;
		max-width: 250px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.locations__note-comment {
		position: static;
		display: block;
		margin: 10px 10px 0px 10px;
	}
}

@media only screen and (max-width: 550px) {
	.locations__sms-numbers-button {
		padding: 5px 10px 5px;
		margin: 8px 8px 8px 8px;
	}

	.locations__sms-numbers-container {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		flex-wrap: wrap;
		align-items: stretch;
	}

	.locations__note-comment span {
		display: inline-block;
		max-width: 280px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.locations__note-comment {
		display: block;
		margin: 20px 10px;
	}

	.locations__note-name {
		float: right;
	}

	.locations__add-new-number {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
	}

	.locations__add-new-number span,
	.locations__add-new-number input {
		margin: 10px 0px;
	}

	.locations__add-new-number span:first-child,
	.locations__add-new-number input:first-child {
		margin: 10px 0px;
	}

	#new_number_api {
		display: block;
		width: 100%;
	}

	#new_number_create_and_assign {
		width: 100%;
	}
}

@media only screen and (max-width: 350px) {
	.locations__note-comment span {
		display: inline-block;
		max-width: 180px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
