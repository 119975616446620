.dh-tabs {
	display: flex;
	flex-flow: row nowrap;
	align-self: center;
	justify-content: flex-start;
	width: 100%;

	border-bottom: 1px solid #f5f5f5;
	padding-left: 30px;
	margin-bottom: 20px;
}

.dh-tab {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	height: 40px;
	min-width: 110px;
	font-size: 15px;
	margin-bottom: -1px;
	border-bottom: 2px solid rgba(0, 0, 0, 0);
	padding-left: 7px;
	padding-right: 7px;

	-webkit-user-select: none; /* Safari */
	-ms-user-select: none; /* IE 10 and IE 11 */
	user-select: none; /* Standard syntax */
}

.dh-tab--selected {
	border-bottom: 2px solid #60a9ff;
}

.dh-tabs--no-padding {
	padding-left: 0px !important;
}

.dh-tab:hover {
	background-color: rgba(96, 169, 255, 0.2);
	cursor: pointer;
	transition: background 0.2s;
}
