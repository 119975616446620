.all-permissions {
	width: 100%;
}

.all-permissions__table {
	width: 100%;
	min-width: 1200px;
	padding: 0 30px;
	margin-top: 50px;
	margin-bottom: 30px;
}

.all-permissions__table__row {
	display: flex;
	height: 65px;
}

.all-permissions__table__row:nth-child(even) {
	background-color: #f6f6f9;
}

.all-permissions__table__row__left-column {
	display: block;
	padding-top: 5px;
	padding-left: 10px;

	width: 100px;
	text-align: left;
	text-decoration: none;
	text-overflow: ellipsis;
	overflow: hidden;
}

.all-permissions__table__row__column {
	position: relative;
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
}

.all-permissions__table__row__column--header {
	display: flex;
	align-items: center;
	flex: 1;
	padding: 5px;
	font-weight: 600;
	text-align: center;
}
