.lagging-locations__last-review-request {
	margin-left: 40px;
	margin-bottom: 15px;
}

.lagging-locations__last-review-request__counts {
	display: flex;
	margin-top: 10px;
}

.lagging-locations__last-review-request__counts .demandhub__status-lights--box {
	margin-right: 5px;
}

.lagging-locations__last-review-request__counts__count {
	margin-right: 10px;
}
