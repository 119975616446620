.dh-textarea-container {
	border: 1px solid #e5e6e7;
	padding: 10px 3px 5px 8px;
	background-color: #fff;
	border-radius: 8px;
	height: 90px;
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
}
.dh-textarea-container__blue:focus-within {
	border-color: #66afe9;
}
.dh-textarea-container__green:focus-within {
	border-color: #1ab394;
}
.dh-textarea-container__disabled {
	background-color: #fafafa;
}
.dh-textarea-container__no-border {
	border: none;
}
.dh-textarea {
	padding: 5px;
	resize: none;
	border: none;
	background-color: #fff;
	height: 30px;
	flex: 1;
	background-image: none;
	box-shadow: none;
}
.dh-textarea:focus {
	box-shadow: none;
}
.dh-textarea[disabled] {
	background-color: #fafafa;
}
.dh-textarea-hide-scroll::-webkit-scrollbar {
	width: 0 !important;
}
.dh-textarea-hide-scroll {
	overflow: -moz-scrollbars-none;
}
.dh-textarea-hide-scroll {
	-ms-overflow-style: none;
}
.dh-border-blue:focus {
	border-color: #66afe9;
}
.dh-textarea__rounded {
	border-radius: 15px;
}
.dh-textarea-feedback-container {
	position: relative;
	top: 5px;
	right: 0;
	display: flex;
	justify-content: space-between;
	margin-top: auto;
	margin-bottom: 1px;
	margin-right: 5px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.dh-textarea-feedback-left-side {
	display: flex;
	justify-content: flex-start;
	flex-flow: row nowrap;
	align-items: center;
}

.dh-textarea-feedback-btn {
	margin-right: 10px;
	cursor: pointer;
	font-weight: 600;
	/* Text not selectable */
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.dh-textarea-feedback-btn:hover {
	color: #66afe9;
	background-color: #fafafa;
}

.dh-textarea-feedback-btn--active {
	color: #66afe9;
	background-color: #fafafa;
}

.dh-textarea-feedback-length-icon {
	padding: 6px 0 0;
}

.dh-textarea-feedback-length {
	color: #bfbfbf;
}
.dh-textarea-feedback-description {
	padding: 5px;
}

.dh-textarea-feedback-container__warning {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	font-size: 11px;
	margin-left: auto;
}

.dh-textarea-feedback-container__warning__icon {
	margin-top: 3px;
	margin-left: 5px;
}

.dh-textarea-feedback-container__file-input {
	display: none !important;
}
