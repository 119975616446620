.demandhub__status-lights--box {
	float: left;
}

.demandhub__status-lights--danger {
	margin: 0 auto;
	background-color: #f00;
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 12px;
	-webkit-animation: blinkRed 0.5s infinite;
	-moz-animation: blinkRed 0.5s infinite;
	-ms-animation: blinkRed 0.5s infinite;
	-o-animation: blinkRed 0.5s infinite;
	animation: blinkRed 0.5s infinite;
}

@-webkit-keyframes blinkRed {
	from {
		background-color: #f00;
	}
	50% {
		background-color: #a00;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
	}
	to {
		background-color: #f00;
	}
}
@-moz-keyframes blinkRed {
	from {
		background-color: #f00;
	}
	50% {
		background-color: #a00;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
	}
	to {
		background-color: #f00;
	}
}
@-ms-keyframes blinkRed {
	from {
		background-color: #f00;
	}
	50% {
		background-color: #a00;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
	}
	to {
		background-color: #f00;
	}
}
@-o-keyframes blinkRed {
	from {
		background-color: #f00;
	}
	50% {
		background-color: #a00;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
	}
	to {
		background-color: #f00;
	}
}
@keyframes blinkRed {
	from {
		background-color: #f00;
	}
	50% {
		background-color: #a00;
		box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #441313 0 -1px 9px, rgba(255, 0, 0, 0.5) 0 2px 0;
	}
	to {
		background-color: #f00;
	}
}

.demandhub__status-lights--warning {
	margin: 0 auto;
	background-color: #ffbf6c;
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #808002 0 -1px 9px, #ff0 0 2px 12px;
}

.demandhub__status-lights--safe {
	margin: 0 auto;
	background-color: #abff00;
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #304701 0 -1px 9px, #89ff00 0 2px 12px;
}

.demandhub__status-lights--info {
	margin: 0 auto;
	background-color: #24e0ff;
	border-radius: 50%;
	box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 7px 1px, inset #006 0 -1px 9px, #3f8cff 0 2px 14px;
}
