input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

@media screen and (max-width: 600px) {
    .messenger-beta {
        display: none !important;
    }
}

@media screen and (min-width: 600px) {
    .mb-mobile {
        display: none !important;
    }
}

.mb-mobile {
    font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: white;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    color: #333;
    overflow: auto;
    padding: 30px;
}

.mb-mobile__text {
    margin-top: 30px;
    font-size: 16px;
}

.messenger-beta {
    font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: white;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    color: #333;
    overflow: auto;
}

.mb-toast {
    font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: #292929 !important;
    color: white !important;
    padding-left: 25px !important;
    font-size: 18px !important;
    z-index: 10000000000000 !important;
}

.mb-toast--error {
	background-color: #ec4758 !important;
}

.mb-toast--small {
    font-size: 14px !important;
}

.mb-tour-step {
    font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 18px;
}

.mb-react-tooltip {
    border-radius: 6px;
    padding: 8px 14px 7px 14px;
    font-weight: 100;
    font-size: 14px !important;
    opacity: 1 !important;
    background-color: #333 !important;
    z-index: 100000000000000 !important;
}

.mb-react-tooltip--medium {
    width: 350px;    
    word-break: break-word;
    white-space: normal;
    word-wrap: break-word;
}

.mb-react-tooltip--large {
    width: 400px;
    min-height: 300px;
    word-break: break-word;
    white-space: normal;
    word-wrap: break-word;
}

.mb-modal {
    font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    color: #333;
}

.mb-modal .modal-body {
    padding: 30px 0px 0px 0px !important;
}

.mb-modal .modal-title {
    font-weight: 100 !important;
}

.mb-input {
    font-size: 22px;
    border: none;
    border-bottom: 1px solid #B6B6B6;
    min-width: 10px;
    width: 100%;
}

.mb-input::placeholder {
    color: #B6B6B6;
}

.mb-input--small {
    font-size: 18px;
}

.mb-button {
    display: inline-block;
    margin-left: 5px;
    border-radius: 100px;
    font-size: 14px;
    border: 2px solid #60A9FF;
    padding: 6px 15px 4px 15px;
    align-self: flex-end;
    background-color: #FFFFFF;
    min-height: 34px;
    -webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.mb-button:hover,
.mb-button:focus {
    color: white;
    background-color: #60A9FF;
    cursor: pointer;
    transition: background 0.2s, border 0.2s, color 0.2s;
}

.mb-button:active {
    color: white;
    background-color: #4A90E2;
}

.mb-button--align-center {
    align-self: center;
}

.mb-button--center {
    margin: auto;
}

.mb-button--right {
    margin-left: auto;
}

.mb-button--cancel {
    border: 2px solid #e74c3c !important;
}

.mb-button--cancel:hover,
.mb-button--cancel:focus {
    border: 2px solid #e74c3c;
    background-color: #e74c3c;
    color: white !important;
    cursor: pointer !important;
}

.mb-button--cancel:active {
    color: white;
    background-color: #b83c30;
}

.mb-button--disabled {
    opacity: 0.6;
}

.mb-button--fit {
    width: fit-content;
}

.mb-button--slim {
    border-width: 1px !important;
}

.mb-button--square {
    border-radius: 6px;
}

.mb-button--full-width {
    width: 100%;
}

.mb-button > a {
    text-decoration: none;
    color: inherit;
}

.mb-button > svg {
    position: relative;
    top: 2px;
}