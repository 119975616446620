.mslm__short-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.mslm__long-url-invalid {
    position: relative;
    top: -8px;
    left: 2px;
}