.mb-navbar {
    background-color: #F6F6F9;
    min-width: 200px;
    max-width: 200px;
    font-size: 20px;
    overflow-y: auto;
    padding-top: 10px;
    z-index: 1020;
}

.mb-navbar__floating-unread {
    position: sticky;
    left: calc(50% - 70px);
    bottom: 10px;
    height: 28px;
    width: 140px;
    font-size: 14px;
    padding: 6px 15px 4px 5px;
    border-radius: 50px;
    border: 2px solid #ec4758;
    background-color: #ec4758;
    color: white;
    margin: 10px 0 10px 0;
    z-index: 1002;
    transition: background 0.2s, border 0.2s;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

}

.mb-navbar__floating-unread:hover {

    border: 2px solid #eb818c;
    background-color: #eb818c;
    cursor: pointer;
    transition: 0.2s;
}

.mb-navbar::-webkit-scrollbar {
	display: none;
}

.mb-navbar-block {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
}

.mb-navbar-block-header {
    font-size: 20px;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.mb-navbar-block-header__team {
    margin-right: auto;
}

.mb-navbar-block-header__scheduled {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #a6a6a6;
    border-radius: 20px;
    color: #FFFFFF;
    padding: 2px 6px 2px 6px;
    margin-right: 6px;
}

.mb-navbar-block-header__scheduled_container{
    display: flex;
    align-items: flex-end;
    width: 100%;
    flex-flow: column;
}

.mb-navbar-block-header__scheduled:hover {
    cursor: pointer;
    opacity: 0.7;
}

.mb-navbar-block-header__scheduled__text {
    font-size: 14px;
    padding-top: 2px;
    padding-left: 7px;
    padding-right: 2px;
}

.mb-navbar-block-header__icon {
    margin-left: auto;
    margin-top: 7px;
    color: #bbbfc4;
}

.mb-navbar-block-header-action {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 16px;
    margin-left: 7px;
    width: 185px;
    border-radius: 5px;
    padding: 8px 10px 5px 13px;
}

.mb-navbar-block-header-action:hover {
    background-color: #e8ecf1;
    color: black;
    cursor: pointer;
}

.mb-navbar-block-header-action__item--unread {
    font-size: 11px;
    width: 20px;
    height: 20px;
    font-weight: bold;
    margin-left: auto;
    background-color: #ec4758;
    color: white;
    padding: 3px 0px 0px 1px;
    border-radius: 50px;
    align-self: baseline;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.mb-navbar-block-subheader {
    font-weight: 900;

    font-size: 12px;
    height: 30px;
    margin-bottom: 10px;
    margin-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.mb-navbar-block-items {
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
}

.mb-navbar-block-item {
    
    font-size: 14px;
    margin-bottom: 3px;
    height: 35px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 0px 10px 0px 10px;
    border-radius: 10px;
    margin-left: 7px;
    width: 185px;
    border: 1px solid #F6F6F9;

    /* Make text not selectable */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.mb-navbar-block-item--text {
    padding-top: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.mb-navbar-block-item:hover {
    background-color: rgba(96, 169, 255, 0.2);
    cursor: pointer;
    transition: background 0.3s, border 0.3s;
    border: 1px solid #60a9ff;
}

.mb-navbar-block-item--hover {
    background-color: #e8ecf1;
    color: black;
}

.mb-navbar-block-item--active {
    background-color: rgba(96, 169, 255, 0.2);
    color: black;
}

.mb-navbar-block-item--group-active {
    border: 1px solid #60a9ff;
}

.mb-navbar-block-item--empty {
    align-self: center;
    font-size: 14px;
}

.mb-navbar-block-item--unassigned {
    opacity: 0.5;
}

.mb-navbar-block-item:hover {
    cursor: pointer;
}

.mb-navbar-block-item-spacer {
	margin-left: auto;
}

.mb-navbar-block-item-action {
    padding-top: 3px;
	padding-left: 10px;
    cursor: pointer;
}

.mb-navbar-block-item--bold {
    color: black;
}

.mb-navbar-block-item--unread {
    font-size: 11px;
    width: 18px;
    height: 18px;
    font-weight: bold;
    margin-left: auto;
    background-color: #ec4758;
    color: white;
    padding: 3px 0px 0px 1px;
    border-radius: 50px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.mb-navbar-block-item--more {
    margin-left: auto;
}

.mb-navbar-block-item--teamchat {

}

.mb-navbar-block-item--teamchat-group-hide {
	display: none;
    margin-left: auto;
}

.mb-navbar-block-item--teamchat-group:hover .mb-navbar-block-item--teamchat-group-hide {
    display: flex;
    cursor: pointer;
    color: #676a6c;
}

.mb-navbar-block-item--teamchat-hide {
    display: none;
    margin-left: auto;
}

.mb-navbar-block-item--teamchat:hover .mb-navbar-block-item--unread {
    display: none;
}

.mb-navbar-block-item--teamchat:hover .mb-navbar-block-item--teamchat-hide {
    display: flex;
    cursor: pointer;
    color: #676a6c;
}


.mb-navbar-block-item--inbox-public {
    display: flex;
    margin-left: 5px;
}

.mb-navbar-block-item--inbox-settings {
    display: none;
    margin-left: auto;
}

.mb-navbar-block-item--inbox:hover .mb-navbar-block-item--unread {
    display: none;
}

.mb-navbar-block-item--inbox:hover .mb-navbar-block-item--inbox-settings {
    display: flex;
    cursor: pointer;
    color: #676a6c;
}

.mb-navbar-block-item--group {

}

.mb-navbar-block-item-group__icon {
    margin-right: 7px;
    position: relative;
    top: 2px;
}

.mb-navbar-block-item-group__convo__icon {
    margin-left: 10px;
    margin-right: 4px;
}

.mb-navbar-block-divider {
    width: 80%;
    height: 2px;
    background-color: #e4e9f0;
    margin-top: 20px;
    margin-bottom: 30px;
    align-self: center;
    margin-left: auto;
    margin-right: auto;
}

.mb-navbar-search-container {
    width: 100%;
    background-color: #e4e9f0;
    display: flex;
    flex-flow: row nowrap;
    border-radius: 20px;

    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
}

.mb-navbar-search-input {
    font-weight: 100;
    height: 30px;
    padding-top: 4px;
    padding-left: 5px;
    width: 90%;
    border: none;
    background-color: #e4e9f0;
}

.mb-navbar-search-input-close {

}

.mb-navbar-top {
    margin-bottom: 30px;
}

.mb-navbar-top--settings {
    margin: 15px 0 10px 0;
}

.mb-navbar-info {
    margin-top: 15px;
    height: 20px;
    margin-left: 15px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}

.mb-navbar-info-icon {
    padding-right: 6px;
    margin-top: 8px;
}

.mb-navbar-info-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-navbar-info-value {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 15px;
    padding-top: 3px;
}

.mb-navbar-info-value:hover {
    opacity: 0.8;
    cursor: pointer;
}
