.mb-location-selector {
    height: 20px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    z-index: 1900;
    position: relative;
}

.mb-location-selector-icon {
    padding-right: 6px;
    padding-top: 3px;
    position: relative;
}

.mb-location-selector-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-location-selector-name {
    max-width: 280px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mb-location-selector-name:hover {
    opacity: 0.8;
    cursor: pointer;
}

@media (max-width: 767px) {
	.mb-location-selector-name {
		display: none;
	}
}

.mb-location-selector-list {
    position: absolute;
    top: 30px;
    right: -15px;
    min-width: 400px;
    max-width: 500px;

    max-height: 600px;
    background-color: white;
    border-radius: 5px;
    padding: 15px 0 15px 0;
    overflow: auto;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 5000;
}

.mb-location-selector-item {
    width: 100%;
    font-size: 16px;
    padding: 0 15px 0 15px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    flex-shrink: 0;
}

.mb-location-selector-item__additional-information{
    width: 100%;
    font-size: 12px;
    opacity: 0.8;
}

.mb-location-selector-item-name {
    margin-top: 9px;
    margin-bottom: 9px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mb-location-selector-item:hover {
	cursor: pointer;
	background-color: rgba(96, 169, 255, 0.2);
	transition: background 0.2s, border 0.2s;
}

.mb-location-selector-item--unread {
    font-size: 11px;
    width: 20px;
    height: 20px;
    font-weight: bold;
    margin-left: auto;
    background-color: #ec4758;
    color: white;
    padding: 3px 0px 0px 0px;
    border-radius: 50px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.mb-location-selector-item--unread-red-dot {
    right: 4px;
    top: 0px;
    position: absolute;
    background-color: #ec4758;
    border-radius: 50px;
    width: 8px;
    height: 8px;
}
