.crm__actions {
    display: flex;
}
.crm__actions div {
    margin-right: 5px;
}

/* crm-integration-schedule = cis */

.cis__list {
    max-width: 500px;
    min-width: 500px;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.cis__list__item {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    
    background-color: white;
    
    width: 100%;
    padding: 15px 20px;
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid #e5e6e7;
}

.cis__list__item:hover {
    background-color: rgba(96, 169, 255, 0.2);
    cursor: pointer;
    transition: background 0.2s;
    border: 1px solid #60a9ff;
}

.cis__list__item__title {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    width: 100%;
}

.cis__list__item__title__last-synced {
    font-weight: 300;
}

.cis__list__item__title__spacer {
    margin-left: auto;
}


.cis__list__item__title__status {
    color: rgb(121, 121, 121);
}

.cis__list__item__title__remove-action {
    margin-left: 15px;
    margin-right: 0px;
    align-self: flex-start;
}

.cis__list__item__schedule-description {
    margin-bottom: 30px;
    font-size: 14px;
    max-width: 400px;
}

.cis__list__item__schedule-description--less-bottom-margin {
    margin-bottom: 5px;
    font-size: 14px;
    max-width: 400px;
}

.cis__list__item__defaults {
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 30px;
}

.cis__list__item__defaults__title {
    margin-bottom: 5px;
}

.cis__list__item__defaults__item {
    border-radius: 8px;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 7px 12px 5px 12px;
    border: 1px solid #e5e6e7;
    background-color: white;
}

.cis__list__item__defaults__item:hover {
    color: #60a9ff;
    cursor: pointer;
    transition: color 0.2s;
    border: 1px solid #60a9ff;
}

.cis__list__add {
    margin-top: 20px;
    align-self: center;
}

.cis__list__empty {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 400px;
    width: 100%;
}

.cis__list__empty__img {
    width: 200px;
}

.cis__list__empty__blurb {
    width: 300px;
    text-align: center;
    margin-top: 30px;
    font-size: 14px;
}