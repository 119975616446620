.mb-tctk {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 20px;
}

.mb-tctk__back {
    font-size: 15px;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
}

.mb-tctk__back__message {
    margin-left: 5px;
    color: #60A9FF;
    font-weight: 600;
    width: fit-content;
}

.mb-tctk__back__message:hover {
    cursor: pointer;
    opacity: 0.7;
}

.mb-tctk__item__header__title {
    font-weight: 900;
}

.mb-tctk__item {
    width: calc(100% - 40px);
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
    margin: 5px 20px 15px 20px;
    border-radius: 8px;
    border: 1px solid white;
    padding: 15px 15px;
}

.mb-tctk__item__content {
    margin-top: 10px;
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.mb-tctk__item__header {
    display: flex;
}

.mb-tctk__item__media {
    display: flex;
    align-items: center;
}

.mb-tctk__item__media__item {
    margin-right: 10px;
}

.mb-tctk__item__header__actions {
    margin-left: auto;
    display: flex;
    position: relative;
}

.mb-tctk__item__date-picker {
    display: flex;
}

.mb-tctk__item__date-picker .react-datepicker-wrapper {
    margin-right: 5px;
}

.mb-tctk__item__editing {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 10px;
}

.mb-tctk__item__editing .mb-buttons {
    margin-right: 5px;
}

.mb-tctk__item__task_content {
    margin-bottom: 10px;
}


.mb-tctk__item__associated_message {
    border: 1px solid #E6E6E6;
    border-left-width: 5px;
    width: 100%;
    margin-right: 20px;
    border-radius: 8px;
    padding: 8px 10px 5px 10px;
    font-size: 11px;
    margin-bottom: 20px;
}


.mb-tctk__item__associated_message:hover {
    cursor: pointer;
}

.mb-tctk__item__associated_message__name {
    font-weight: 900;
}

.mb-tctk__item__associated_message__content {
    margin-bottom: 0;
    width: 100%;
    word-break: break-word;
}
