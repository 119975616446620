.edit-templates {
	width: 100%;
	height: 580px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.edit-templates__body {
}

.edit-templates__body__name {
	margin-top: 0;
}

.edit-templates__body__checkbox {
	margin-top: 0;
}
