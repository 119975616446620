.mb-tcsm {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    padding-bottom: 20px;
    overflow: scroll;
}

.mb-tcsm__back {
    width: 100%;
    font-size: 18px;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 15px;
    display: flex;
}

.mb-tcsm__back__message {
    margin-right: 5px;
    color: #60A9FF;
    font-weight: 600;
    width: fit-content;
    height: 27px;
}

.mb-tcsm__back__message:hover {
    cursor: pointer;
    opacity: 0.7;
}

.mb-tcsm__back__select {
    font-size: 15px;
    margin-left: auto;
    margin-right: 45px;
    width: 200px;
    position: relative;
    z-index: 10001;
}

.mb-tcsm__back__select__label {
    position: absolute;
    top: -16px;
    font-size: 12px;
}

.mb-tcsm__back__select__select {
    position: absolute;
}

.mb-tcsm__item {
    width: calc(100% - 40px);
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
    margin: 5px 20px 15px 20px;
    border-radius: 8px;
    border: 1px solid white;
    padding: 15px 15px;
}

.mb-tcsm__item__content {
    margin-top: 10px;
    max-height: 340px;
    overflow-x: hidden;
    overflow-y: auto;
}


.mb-tcsm__item__media {
    display: flex;
    align-items: center;
}

.mb-tcsm__item__media__item {
    margin-right: 10px;
}

.mb-tcsm__item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mb-tcsm__item__header__text {
    display: flex;
    flex-direction: row;
}

.mb-tcsm__item__header__text__name {
    font-weight: 600;
    margin-right: 10px;
}

.mb-tcsm__item__header__actions {
    margin-left: auto;
    display: flex;
    position: relative;
}

.mb-tcsm__item__date-picker {
    display: flex;
}

.mb-tcsm__item__date-picker .react-datepicker-wrapper {
    margin-right: 5px;
}

.mb-tcsm__item__editing {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: 10px;
}

.mb-tcsm__item__editing .mb-buttons {
    margin-right: 5px;
}