.settings {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	background-color: white;
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-flow: row nowrap;
	color: #333;
	overflow: auto;
}

.settings__navbar {
	background-color: #f6f6f9;
	min-width: 200px;
	font-size: 20px;
	overflow-y: auto;
}

.settings__navbar__header {
	font-weight: 900;
	font-size: 12px;
	height: 30px;
	margin-bottom: 0px;
	margin-top: 20px;
	padding-left: 15px;
	padding-right: 15px;
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
}

.settings__navbar__list {
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: center;
}

.settings__navbar__list__item {
	font-size: 14px;
	margin-bottom: 3px;
	height: 35px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	padding: 2px 10px 0px 10px;
	border-radius: 10px;
	margin-left: 7px;
	width: 185px;
	border: 1px solid #f6f6f9;
}

.settings__navbar__list__item:hover {
	background-color: rgba(96, 169, 255, 0.2);
	cursor: pointer;
	transition: background 0.3s, border 0.3s;
	border: 1px solid #60a9ff;
}

.settings__navbar__list__item--active {
	background-color: rgba(96, 169, 255, 0.2);
	color: black;
}

.settings-search {
	position: relative;
	padding: 0 30px 45px 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.settings-search__limit {
	position: absolute;
	top: 45px;
	padding-left: 5px;
	font-size: 12px;
	opacity: 0.8;
}
