/* bwc === booking-widget-config */

.bwc {
	display: flex;
	flex-flow: row wrap-reverse;
	align-items: flex-end;
	justify-content: flex-start;

	margin: 0 30px 20px;
	width: calc(100% - 60px);
	min-width: 400px;
}

.bwc__options {
	padding: 0 30px 30px;
	max-width: 500px;
	min-width: 400px;
}

.bwc__options__field {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;

	border-bottom: 1px solid #f5f5f5;
	font-size: 14px;

	padding-top: 20px;
	padding-bottom: 20px;
	width: 350px;
	margin: auto;
}

.bwc__options__field:first-child {
	padding-top: 0;
}

.bwc__options__field--no-border {
	border: none !important;
}

.bwc__options__field__label {
	flex: 1;
	width: 275px;
}

.bwc__options__field__toggles__label {
	margin-top: 10px;
}

.bwc__options__field__toggles__switch {
	margin-top: 10px;
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	width: 200px;
}

.bwc__options__field__toggles__switch__btn {
	margin-left: auto;
}

.bwc__options__save-actions {
	margin-top: 20px;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
