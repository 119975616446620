.payment-request-list__search {
	width: 100%;
	padding: 0 30px 30px 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	position: relative;
}

.payment-request-list__search__actions {
	margin-top: auto;
	padding-right: 15px;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
}

.payment-request-list__search__actions__dropdown-action {
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.payment-request-list__search__actions__dropdown-action__text {
	padding-top: 2px;
	margin-left: 5px;
	margin-right: 8px;
}

