.modal-gmb-locations-sub-header {
	padding: 0px 5px 10px;
	text-align: center;
	font-size: 16px;
}

.modal-gmb-locations-list {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.gmb-location-item {
	margin: 10px 0;
	padding: 15px 10px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 5px;
	display: flex;
	width: 100%;
}

.gmb-location-item.selected {
	border: 1px solid rgba(81, 148, 227, 1);
}

.gmb-location-item span {
	padding: 0px 5px;
}

.gmb-location-item__location-name {
	font-weight: 600;
}

.gmb-location-item:hover {
	background-color: #e2efff;
	cursor: pointer;
}
