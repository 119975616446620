.delete-inbox-modal__input {
    border: 1px solid #e5e6e7;
    border-radius: 8px;
    height: 40px;
    font-size: 14px; 
    padding-left: 10px; 
    width: 100%;
}

.delete-inbox-modal__type-name {
    margin-top: 10px;
}

.delete-inbox-modal__actions {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;   
}

.delete-inbox-modal__select {
    width: 400px;
}

.delete-inbox-modal__workflows {
    margin-top: 10px;
}