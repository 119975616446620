.ChatbotConfig__title {
	display: flex;
	align-items: center;
}

.ChatbotConfig__back-button {
	cursor: pointer;
	width: 30px;
	margin-top: 15px;
}

.ChatbotConfig__box-heading {
	display: flex;
	align-items: center;
	margin: 0 30px 15px 30px;
}

.ChatbotConfig__is-permissible {
	display: flex;
	align-items: center;
}

.ChatbotConfig__default-chatbot-selector {
	margin-left: auto;
}
