.dh__container {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100vw;
	height: 100vh;
}

.dh__container__core {
	height: 100vh;
	width: calc(100vw - 240px);
	flex-grow: 1;
	display: flex;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;
}
