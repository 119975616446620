.Leaderboard__table--padding {
	padding: 1% !important;
}
.th__icon--button {
	margin-left: 0.3em;
	cursor: pointer;
	-webkit-transition-duration: 0.4s; /* Safari */
	transition-duration: 0.4s;
}
.Leaderboard__text--nowrap {
	white-space: nowrap;
}
.th__icon--button:hover {
	color: #0099cc; /* Green */
}

.leaderboard__search {
	margin: 0 30px 30px 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}
