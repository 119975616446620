.mb-convo {
    height: 90px;
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid #F1F1F1;
    border-left: 3px solid white;
    position: relative
}

.mb-convo--active {
    border-left: 3px solid #60A9FF !important;
}


.mb-convo--bold {
    /* font-weight: bold; */
    background-color: #E7F2FF;
}

.mb-convo:hover {
    background-color: #F6F6F9;
    border-left: 3px solid #F6F6F9;
    cursor: pointer;
    transition: background 0.2s;
}

.mb-convo-error
{
    margin-left: 5px;
}

.mb-convo-top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    white-space: nowrap;
    overflow: hidden;
    height: 28px;
}

.mb-convo-name {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    align-self: flex-start;
}

.mb-convo-name--unknown {
    font-style: italic;
}

.mb-convo-date {
    font-size: 12px;
    align-self: flex-end;
    margin-bottom: 7px;
}

.mb-convo-bottom {
    margin-top: 15px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}

.mb-convo-content {
    width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mb-convo-unread {
    font-size: 12px;
    font-weight: bold;
    margin-left: auto;
    background-color: #60A9FF;
    color: white;
    /* border: 1px solid #60A9FF; */
    padding: 3px 10px 0px 10px;
    border-radius: 50px;
    margin-left: 5px;
}

.mb-convo-user {
    margin-left: 10px;
    font-size: 10px;
    background-color: #f1f2f6;
    padding: 3px 7px 3px 7px;
    border-radius: 20px;
    color: #676a6c;
    white-space: nowrap;
    margin-right: 5px;
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 7px;
}

.mb-convo-inbox {
    margin-left: 10px;
    font-size: 8px;
    background-color: #f1f2f6;
    padding: 3px 7px 3px 7px;
    border-radius: 20px;
    color: #676a6c;
    white-space: nowrap;
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 1px;
}

.mb-convo-bottom__nudge {
    margin-left: 10px;
    font-size: 10px;
    background-color: #e0eeff;
    padding: 3px 7px 3px 7px;
    border-radius: 20px;
    color: #1480ff;
    white-space: nowrap;
    margin-right: 5px;
    align-self: flex-end;
    margin-left: auto;
    margin-bottom: 7px;
}

.mb-convo-top__spacer {
    margin-left: auto;
}

.mb-convo:hover .mb-convo__medium {
    width: 14px;
}

.mb-convo__medium {
    margin-right: 5px;
    margin-top: 3px;
    transition: width 0.1s ease-in-out;
    width: 0px;
}