.ReviewsInsights__negativeInsights--title {
	margin-top: 50px;
	margin-bottom: 20px;
}
.ReviewsInsights__positiveInsights--title {
	margin-bottom: 20px;
}
.ReviewInsights__information--icon {
	color: blue;
	transform: scale(0.7);
}
.ReviewInsights__information--text {
	font-size: smaller;
	font-style: italic;
	margin-bottom: 10px;
}
.ReviewInsights__noun-adjectives {
	background-color: lightgray;
	color: black;
	padding: 2px 10px;
	margin-right: 10px;
	margin-bottom: 20px !important;
}
.ReviewInsights__noun-adjectives:hover {
	cursor: default;
}
.ReviewsInsights__Keywords--score {
	position: relative;
	margin-bottom: 10px;
	width: 100%;
	display: flex;
	flex-direction: column;
}
.ReviewsInsights__scoreline {
	position: absolute;
	height: 12%;
	bottom: 0%;
	background-image: linear-gradient(to right, red, gold, chartreuse);
}
.ReviewsInsights__Container {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 20px;
}
.ReviewInsights__keywordContainer {
	width: 200px;
}
.ReviewsInsights__Insights {
	width: 100%;
}
.ReviewsInsights__reviewcontent {
	margin-bottom: 30px;
}
.hidden-reviews {
	display: none;
}
.ReviewsInsights__bigSmileyFace {
	/* background-image: url("/img/icons/png/smileys/bigsmiley.png"); */
	background-repeat: no-repeat;
	background-size: 100%;
	width: 50px;
	height: 50px;
	margin-bottom: 7px;
}
.ReviewsInsights__smileyFace {
	/* background-image: url("/img/icons/png/smileys/smiley.png"); */
	background-repeat: no-repeat;
	background-size: 100%;
	width: 50px;
	height: 50px;
	margin-bottom: 7px;
}
.ReviewsInsights__neutralFace {
	/* background-image: url("/img/icons/png/smileys/neutral.png"); */
	background-repeat: no-repeat;
	background-size: 100%;
	width: 50px;
	height: 50px;
	margin-bottom: 7px;
}
.ReviewsInsights__sadFace {
	/* background-image: url("/img/icons/png/smileys/sad.png"); */
	background-repeat: no-repeat;
	background-size: 100%;
	width: 50px;
	height: 50px;
	margin-bottom: 7px;
}
.ReviewsInsights__verySadFace {
	/* background-image: url("/img/icons/png/smileys/verysad.png"); */
	background-repeat: no-repeat;
	background-size: 100%;
	width: 50px;
	height: 50px;
	margin-bottom: 7px;
}

.ReviewInsights-nav {
	padding-left: 0px;
	margin-bottom: 0;
	list-style: none;
}
.ReviewInsights-nav > li {
	float: left;
	position: relative;
	display: block;
}

.ReviewInsights-nav > li > a {
	color: #676a6c;
	font-weight: 600;
	font-size: 13px;
	padding: 10px 14px 10px 0px;
}

.ReviewInsights-nav > li.active > a,
.ReviewInsights-nav > li.active > a:focus,
.ReviewInsights-nav > li.active > a:hover {
	color: #52dd4f;
	font-weight: 600;
	text-decoration: underline;
}

@media (max-width: 767px) {
	.ReviewsInsights__Insights {
		width: 100%;
	}
	.ReviewsInsights__bigSmileyFace {
		width: 25px;
		height: 25px;
	}
	.ReviewsInsights__smileyFace {
		width: 25px;
		height: 25px;
	}
	.ReviewsInsights__neutralFace {
		width: 25px;
		height: 25px;
	}
	.ReviewsInsights__sadFace {
		width: 25px;
		height: 25px;
	}
	.ReviewsInsights__verySadFace {
		width: 25px;
		height: 25px;
	}
}
