.alert__contents {
	max-width: 500px;
	align-self: center;
	font-size: 18px;
	margin-top: 30px;
	word-break: break-word;
}

.alert__actions {
	margin-top: auto;
	align-self: flex-end;

	display: flex;
	flex-flow: row nowrap;

	margin-top: 50px;
}

.alert__icon {
	color: #c9dae1;
}

.alert__icon svg {
	height: 75px;
	width: 75px;
}

.alert__icon--success {
	color: #a5dc86;
}

.alert__icon--error {
	color: #f27474;
}

.alert__icon--warning {
	color: #f3c398;
}
