/* wfm = workflow-recipes-modal */

.wfm {
    position: fixed;
    z-index: 1021;
    transform: translateX(-390px) translateY(-10px);
    height: 250px;
    width: 350px;
    background-color: white;
    margin: 10px 20px 59px 30px;
    padding: 15px 15px;
    border-radius: 8px;
    overflow-y: auto;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: visibility 0s, opacity 0.2s ease-in-out;
}

.wfm--hidden {
    opacity: 0;
    visibility: none;
    pointer-events: none;
}

.wfm--visible {
    visibility: visible;
    opacity: 1;
}

.wfm__header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.wfm__header__search {
    width: 50%;
    margin-right: 10px;
}

.wfm__header__x {
    cursor: pointer;
    height: fit-content;
}

.wfm__header__x:hover {
    opacity: 0.8;    
}

.wfm__item {
    font-size: 14px;
    padding: 10px 20px 10px 5px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.wfm__item:hover{
    cursor: pointer;
    background-color: rgba(96, 169, 255, 0.2);
    border-radius: 8px;
    transition: background 0.2s, border 0.2s;
}

.wfm__item__content {
    display: flex;
    flex-direction: column;
}

.wfm__item__icon {
    margin-right: 5px;
    width: 20px;
}

.wfm__item__icon svg {
    position: relative;
    top: 3px;
}

.wfm__item__content__name {
    margin-right: 10px;
    min-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wfm__item__content__description {
    font-size: 12px;
    opacity: 0.6;
    overflow: hidden;
    text-overflow: ellipsis;
}