.voicemail-inbox {
    width: 100%;
    padding: 0 30px 10px 30px;
    margin-top: 15px;
}

.voicemail-inbox-search {
    margin-top: 15px;
    margin-bottom: 25px;
    padding-left: 30px;
}

.voicemail-inbox-load-more {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0 40px;
    text-align: center;
}

.voicemail-inbox__none {
    margin: 15px 0 30px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.voicemail-inbox__none__img {
    max-width: 350px;
    margin: auto;
}

.voicemail-inbox__none__text {
    text-align: center;
    font-size: 20px;
    color: #868b98;
}

.voicemail {
    width: 100%;
    padding: 15px;
    padding-bottom: 0;
    border-radius: 8px;
    background-color: #f6f6f9;
    margin-bottom: 25px;
    display: flex;
}

.voicemail--no-text {
    padding-bottom: 15px;
}

.voicemail__icon {
    width: 50px;
    margin-right: 25px;
    margin-left: 10px;
    height: 40px;
}

.voicemail__content {
    width: 100%;
}

.voicemail__content__header {
    display: flex;
    height: 40px;
}

.voicemail__content__header__name,
.voicemail__content__header__date {
    display: flex;
    align-items: flex-start;
    margin-top: 7px;
}

.voicemail__content__header__name {
    font-size: 16px;
    flex: 0 0 100px;
    position: relative;
}

.voicemail__contect__header__name__text {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.voicemail__content__header__date {
    display: flex;
    justify-content: center;
    flex: 0 0 120px;
    font-size: 16px;
    text-align: center;
}

.voicemail__content__header__soundwave {
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
}

.voicemail__content__header__actions {
    margin-left: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    position: relative;
    width: 80px;
    flex: 0 0 80px;
}

.voicemail__content__text {
    margin-top: 15px;
    padding-bottom: 15px;
    position: relative;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1; /* number of lines to show */
            line-clamp: 1;
    -webkit-box-orient: vertical;

    max-height: 34px;
}

.voicemail__content__text--expanded {
    display: block;

    overflow-y: auto;
    max-height: 200px;
}

.voicemail__content__text__overlay {
    pointer-events: none;
	position: absolute;
	bottom: 0px;

	height: 40px;
	width: 100%;

	background: linear-gradient(rgba(255, 255, 255, 0), rgb(246, 246, 249, 1));
}

.voicemail__content__text--expanded .voicemail__content__text__overlay {
    background: rgba(255, 255, 255, 0);
}

.voicemail-settings {
    margin: 0 20px;
    width: 500px;
}

.voicemail-settings__cards__select {
    text-transform: capitalize;
    z-index: 1001;
}

.voicemail-settings__cards__audio {
    position: relative;
    border: 1px solid #e5e6e7;
    border-radius: 8px;
    padding: 15px 20px;
}

.voicemail-settings__cards__card__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.voicemail-settings .mb-media__audio__button {
	margin-left: 0;
	display: flex;
	align-items: center;
}

.voicemail-settings .mb-media__audio__wave {
	margin-right: 0;
}

@media (max-width: 930px) {
    .voicemail__content__header__date {
        flex: 0 0 100px;
    }
}

@media (min-width: 600px){
    .voicemail--mobile {
        display: none !important;
    }
}

@media (max-width: 600px) {
    .voicemail--desktop {
        display: none !important;
    }

    .voicemail--mobile {
        display: flex;
        flex-direction: column;
        min-width: 215px;
    }

    .voicemail--mobile .mb-media__audio__button {
        margin-left: 0;
        margin-right: 10px;
    }

    .voicemail__content__header__name {
        flex: none;
    }

    .voicemail__content__header__date {
        flex: none;
        align-self: flex-end;
        font-size: 13px;
        opacity: 0.8;
    }

    .voicemail__content__header__actions {
        align-self: flex-start;
    }

    .voicemail__content__header__soundwave {
        flex: 0 0 120px;
        padding: 0;
    }

    .voicemail-inbox {
        padding: 0 15px 10px 15px;
        margin-top: 15px;
    }

    .voicemail-inbox__none__img {
        max-width: 200px;
    }
}