#suggestions-page .page-heading {
	border-bottom: none !important;
}

.NPS__center-score {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}

#suggestions-page__open_nps_button {
	margin-top: 10px;
	position: relative;
}

#suggestions-page__open_nps_button button {
	position: absolute;
	right: 0px;
}

.media-body {
	background-color: white;
}

.media {
	background-color: white;
	padding: 20px;
}

.NPS__center-score div span {
	margin: 0;
	font-size: 2.2em;
	font-weight: 700;
}

.NPS__center-score div p {
	margin: 0;
	font-size: 9px;
	font-weight: bold;
}

.NPS__promoters {
	color: #5bb85b !important;
}

.NPS__detractors {
	color: #d9534f !important;
}

.NPS__passives {
	color: #f1ad4e !important;
}

.NPS-details {
	margin-top: -5px;
	margin-bottom: 10px;
}

.NPS-details span {
	font-size: 9px;
	font-weight: bold;
}

.NPS__container {
	position: relative;
	margin-right: 256px;
}

.NPS__side-panel {
	width: 245px;
	position: absolute;
	z-index: 1;
	right: -256px;
	padding: 10px;
	background-color: white;
	top: 0px !important;
}

.NPS__side-panel-content {
	display: block;
}

.NPS__filter--container {
	display: none;
}

#dh-npsChart-suggestions {
	height: 80px;
	max-height: 80px;
	width: 80px;
	max-width: 80px;
}

.nps-anonymous-field {
	display: flex;
}

.nps-anonymous-field .checkbox-container {
	margin-left: 10px;
	margin-top: -3px;
}

@media screen and (max-width: 890px) {
	.NPS__side-panel {
		top: 172px;
	}
}

@media screen and (max-width: 786px) {
	.NPS__side-panel {
		display: none;
	}

	.NPS__filter--container {
		display: block;
	}

	#dh-npsChart-suggestions {
		height: 90%;
		max-height: 90%;
		width: 90%;
		max-width: 90%;
	}
}

@media screen and (max-width: 786px) {
	.NPS__side-panel {
		display: none;
	}

	.NPS__filter--container {
		display: block;
	}

	#dh-npsChart-suggestions {
		height: 90%;
		max-height: 90%;
		width: 90%;
		max-width: 90%;
	}
}
