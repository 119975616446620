#settings-page {
	width: 500px;
}

.settings-page__form {
	width: 100%;
}

.settings-page__form__details {
	width: 100% !important;
}

.settings-page__not-found {
	margin: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.settings-page__not-found--text {
	font-size: 21px;
}
