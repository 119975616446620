.dh-lexical__editor-container {
    width: 100%;
}

.dh-lexical__compose {
    position: relative;
    width: 100%;
    height: 100%;
}

.dh-lexical__content-editable {
    width: 100%;
    height: 100%;
    min-height: 100px;
    max-height: 200px;
    overflow-y: auto;

    line-height: 24px;
    font-size: 16px;
    resize: none;
    border: none;
    padding: 0;
    flex-grow: 1;
    background-color: inherit;
}

.dh-lexical__input {
    min-height: 150px;
    resize: none;
    font-size: 15px;
    caret-color: rgb(5, 5, 5);
    position: relative;
    tab-size: 1;
    outline: 0;
    padding: 15px 10px;
    caret-color: #444;
}

.dh-lexical__placeholder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 24px;
    font-size: 16px;
    opacity: 0.8;
    
    user-select: none;
    pointer-events: none;
}


.dh-lexical__toolbar__button {
    margin-left: 5px;
    margin-right: 5px;
}

.dh-lexical__toolbar__button:hover {
    cursor: pointer;
}

.dh-lexical-editor-textBold{
    font-weight: bold;
}

.dh-lexical-editor-textItalic{
    font-style: italic;
}

.dh-lexical-editor-paragraph{
    margin: 0;
    position: relative;
}

.dh-lexical-editor-list-ul {
    margin: 0;
    padding: 0;
    margin-left: 16px;
}

.dh-lexical-editor-list-ol {
    margin: 0;
    padding: 0;
    margin-left: 16px;
}

.dh-lexical-editor-textUnderline {
    text-decoration: underline;
  }

.dh-lexical-editor-textStrikethrough {
    text-decoration: line-through;
}

.dh-lexical-editor-textUnderlineStrikethrough {
    text-decoration: underline line-through;
}

.dh-lexical-editor-textBold {
    font-weight: bold;
}

.dh-lexical-editor-textItalic {
    font-style: italic;
}

.dh-lexical-editor-textunderline {
    text-decoration: underline;
}

.dh-lexical-editor-textstrikethrough {
    text-decoration: line-through;
}

.dh-lexical-editor-textunderlineStrikethrough {
    text-decoration: underline line-through;
}

  
.dh-lexical-editor-code {
    background-color: rgb(240, 242, 245);
    font-family: Menlo, Consolas, Monaco, monospace;
    display: block;
    padding: 8px 8px 8px 52px;
    line-height: 1.53;
    font-size: 13px;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 8px;
    tab-size: 2;
    /* white-space: pre; */
    overflow-x: auto;
    position: relative;
}

.dh-lexical-editor-code:before {
    content: attr(data-gutter);
    position: absolute;
    background-color: #eee;
    left: 0;
    top: 0;
    border-right: 1px solid #ccc;
    padding: 8px;
    color: #777;
    white-space: pre-wrap;
    text-align: right;
    min-width: 25px;
}

.dh-lexical-editor-code:after {
    content: attr(data-highlight-language);
    top: 0;
    right: 3px;
    padding: 3px;
    font-size: 10px;
    text-transform: uppercase;
    position: absolute;
    color: rgba(0, 0, 0, 0.5);
}
  
.dh-lexical-editor-tokenComment {
    color: slategray;
}

.dh-lexical-editor-tokenPunctuation {
    color: #999;
}

.dh-lexical-editor-tokenProperty {
    color: #905;
}

.dh-lexical-editor-tokenSelector {
    color: #690;
}

.dh-lexical-editor-tokenOperator {
    color: #9a6e3a;
}

.dh-lexical-editor-tokenAttr {
    color: #07a;
}

.dh-lexical-editor-tokenVariable {
    color: #e90;
}

.dh-lexical-editor-tokenFunction {
    color: #dd4a68;
}

.dh-lexical-editor-heading-h1 {
    font-size: 24px;
    color: rgb(5, 5, 5);
    font-weight: 400;
    margin: 0;
    margin-bottom: 12px;
    padding: 0;
}

.dh-lexical-editor-heading-h2 {
    font-size: 15px;
    color: rgb(101, 103, 107);
    font-weight: 700;
    margin: 0;
    margin-top: 10px;
    padding: 0;
    text-transform: uppercase;
}

.dh-lexical-editor-quote {
    margin: 0;
    margin-left: 20px;
    font-size: 15px;
    color: rgb(101, 103, 107);
    border-left-color: rgb(206, 208, 212);
    border-left-width: 4px;
    border-left-style: solid;
    padding-left: 16px;
}



.dh-lexical__toolbar {
    display: flex;
    margin-bottom: 6px;
    background: #fff;
    padding: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    vertical-align: middle;
}

.dh-lexical__toolbar button.dh-lexical__toolbar__toolbar-item {
    border: 0;
    display: flex;
    background: none;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
    vertical-align: middle;
}

.dh-lexical__toolbar button.dh-lexical__toolbar__toolbar-item:disabled {
    cursor: not-allowed;
}

.dh-lexical__toolbar button.dh-lexical__toolbar__toolbar-item.spaced {
    margin-right: 2px;
}

.dh-lexical__toolbar button.dh-lexical__toolbar__toolbar-item .dh-lexical__toolbar__icon {
    background-size: contain;
    display: inline-block;
    height: 18px;
    width: 18px;
    margin-top: 2px;
    vertical-align: -0.25em;
    display: flex;
    opacity: 0.6;
}

.dh-lexical__toolbar button.dh-lexical__toolbar__toolbar-item:disabled .dh-lexical__toolbar__icon {
    opacity: 0.2;
}

.dh-lexical__toolbar button.dh-lexical__toolbar__toolbar-item.active {
    background-color: rgba(223, 232, 250, 0.3);
}

.dh-lexical__toolbar button.dh-lexical__toolbar__toolbar-item.active i {
    opacity: 1;
}

.dh-lexical__toolbar .dh-lexical__toolbar__toolbar-item:hover:not([disabled]) {
    background-color: #eee;
}

.dh-lexical__toolbar .divider {
    width: 1px;
    background-color: #eee;
    margin: 0 4px;
}

.dh-lexical__toolbar .dh-lexical__toolbar__toolbar-item .text {
    display: flex;
    line-height: 20px;
    width: 200px;
    vertical-align: middle;
    font-size: 14px;
    color: #777;
    text-overflow: ellipsis;
    width: 70px;
    overflow: hidden;
    height: 20px;
    text-align: left;
}

.dh-lexical__toolbar .dh-lexical__toolbar__toolbar-item .icon {
    display: flex;
    width: 20px;
    height: 20px;
    user-select: none;
    margin-right: 8px;
    line-height: 16px;
    background-size: contain;
}

.dh-lexical__toolbar__icon.bold {
    background-image: url(icons/type-bold.svg);
}

.dh-lexical__toolbar__icon.italic {
    background-image: url(icons/type-italic.svg);
}

.dh-lexical__toolbar__icon.underline {
    background-image: url(icons/type-underline.svg);
}

.dh-lexical__toolbar__icon.strikethrough {
    background-image: url(icons/type-strikethrough.svg);
}

.dh-lexical__toolbar__icon.list-ul {
    background-image: url(icons/list-ul.svg);
}

.dh-lexical__toolbar__icon.list-ol {
    background-image: url(icons/list-ol.svg);
}

.dh-lexical__tree-view-output {
    display: block;
    background: #222;
    color: #fff;
    padding: 5px;
    font-size: 12px;
    white-space: pre-wrap;
    margin: 1px auto 10px auto;
    max-height: 250px;
    position: relative;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: auto;
    line-height: 14px;

    position: fixed;
    top: 80px;
    right: 0px;
}
