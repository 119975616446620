.mb-emojis {
    position: fixed;
    z-index: 999999;
    height: 500px;
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    overflow: auto;
    box-shadow: 0 1px 5px rgb(0 0 0 / 15%);
}

.mb-emojis-x {
    margin-left: auto;
    cursor: pointer;
    display: flex;
    flex-flow: row-reverse nowrap;
    margin-bottom: 10px;
}

.mb-emojis-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    font-size: 44px;
}

.mb-emojis-list-item {
    margin: 5px;
    cursor: pointer;
}
