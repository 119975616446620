.sm-modal {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.sm-modal__field {
	margin-top: 10px;
	margin-bottom: 5px;
	margin-left: 3px;
}

.sm-modal__field__recipients__title {
	display: flex;
	width: 100%;
	align-items: center;
}

.sm-modal__field__bulk_upload {
	margin-left: auto;
	text-decoration: underline;
	font-size: 10px;
	color: #60a9ff;
	margin-right: 3px;
}

.sm-modal__field__bulk_upload:hover {
	cursor: pointer;
}

.sm-modal__field__info {
	margin-left: 5px;
	height: 13px;
}

.sm-modal__field__info > svg {
	position: relative;
	top: 3px;
	color: #60a9ff;
}

.sm-modal__quota {
	background-color: rgba(96, 169, 255, 0.2);
	width: 100%;
	padding: 13px 15px 10px 15px;
	border-radius: 8px;
	margin-bottom: 10px;
}

.sm-modal__limit-reached,
.sm-modal__no-mms {
	background-color: rgba(236, 71, 88, 0.2);
	width: 100%;
	max-width: 500px;
	padding: 13px 15px 10px 15px;
	border-radius: 8px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.sm-modal__search {
	width: 100%;
	margin-bottom: 0;
	max-width: 500px;
}

.sm-modal__template {
	width: 100%;
	margin-bottom: 10px;
	max-width: 500px;
}

.sm-modal__attachments {
	width: 100%;
	margin-top: 10px;
}

.sm-modal__schedule {
	margin-top: 10px;
	margin-bottom: 10px;
}

.sm-modal__schedule__datepicker {
	width: 230px;
	text-align: center;
	border: 1px solid #e5e6e7;
	border-radius: 8px;
	padding: 10px 3px 5px 8px;
}

.sm-modal__schedule__info_icon {
	display: inline-block;
	vertical-align: middle;
	margin-left: 5px;
}

.sm-modal__actions {
	margin-top: auto;
	align-self: flex-end;

	display: flex;
	flex-flow: row nowrap;

	margin-top: 50px;
}

.sm-modal__select {
	width: 100%;
}

.sm-modal__review_invite_preview {
	margin-top: 20px;
	width: 100%;
}

.sm-modal__review_invite_preview__title_container {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;
	white-space: nowrap;
	overflow: hidden;
}

.sm-modal__review_invite_preview__title {
	display: inline-block;
}

.sm-modal__review_invite_preview__switch {
	display: inline-block;
}

.sm-modal__review_invite_preview .sm-modal__text-bubble {
	width: 90%;
}

.sm-modal__review_invite_preview .sm-modal__text-bubble .talk-bubble {
	max-width: 450px;
	min-width: 450px;
}

.sm-modal__review_invite_preview .sm-modal__text-bubble .talktext {
	min-height: 90px;
}

.sm-modal__review_invite_preview__info_icon {
	position: relative;
	top: 3px;
	margin-left: 5px;
}

.sm-modal__send-draft--off {
	opacity: 0.6;
}

.sm-modal__send-draft:hover {
	cursor: pointer;
}
