.mb-message-customer-error {
    height: 25px;
    width: 25px;
    min-width: 25px;
    margin-right: 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    z-index: 1001;
    margin-bottom: 50px;
}

.mb-message-customer-error-container {
    position: relative;
}

.mb-message-customer-error:hover {
    cursor: pointer;
}

.mb-message-customer-error__info {
    display: flex;
}

.mb-message-customer-error__tooltip{
    max-width: 400px;
}