.mb-pr {
    width: 700px;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.mb-pr__loader {
    margin-top: 10px;
}

.mb-pr__loader__title {
    font-size: 16px;
}

.mb-pr__description {
    font-size: 14px;
}

.mb-pr__graphic {
    max-width: 250px;
    align-self: center;
    margin-top: 30px;
}

.mb-pr-icon {
    margin-left: auto;
}

.mb-pr-icon:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-pr__actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    align-self: flex-end;
    width: 100%;
    margin-top: auto;
}

.mb-pr__line-items {
    margin-top: 30px;
    width: 100%;
}

.mb-pr__line-items___title {
    font-size: 20px;
    margin-bottom: 15px;
}

.mb-pr__line-items__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 15px;
}

.mb-pr__line-items__item__input {
    margin-top: 3px;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #E7E7E7;
    width: 350px;
    margin-right: 35px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.mb-pr__line-items__item__input::placeholder {
    color: #E7E7E7
}

.mb-pr__line-items__item__input--small {
    width: 150px !important;
}

.mb-pr__line-items__item__plus {
    margin-left: auto;
    width: 50px;
}

.mb-pr__line-items__item__plus:hover {
    opacity: 0.8;
    cursor: pointer;
}

.mb-pr__line-items__subtotal {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 15px;
}

.mb-pr__line-items__subtotal__title {
    font-weight: 900;
    margin-top: 3px;
    font-size: 14px;
    border: none;
    min-width: 10px;
    width: 350px;
    margin-right: 35px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.mb-pr__line-items__subtotal__amount {
    font-weight: 900;
    margin-top: 3px;
    font-size: 14px;
    border: none;
    min-width: 10px;
    width: 150px;
    margin-right: 35px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.mb-pr__line-items__taxes {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-top: 15px;
}

.mb-pr__line-items__taxes__title {
    font-weight: 900;
    margin-top: 3px;
    font-size: 14px;
    border: none;
    min-width: 10px;
    width: 350px;
    margin-right: 35px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.mb-pr__line-items__taxes__amount {
    font-weight: 900;
    margin-top: 3px;
    font-size: 14px;
    border: none;
    min-width: 10px;
    width: 150px;
    margin-right: 35px;
    padding-bottom: 5px;
    padding-left: 5px;
}

.mb-pr__toggle {
    margin-top: 30px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
}

.mb-pr__toggle__title {
    font-size: 18px;
}

.mb-pr__toggle__input {
    margin-left: 10px;
    margin-top: -10px;
}

.mb-pr__charge-date {
    border: 1px solid #e5e6e7;
    border-radius: 8px;
    padding: 8px;
    margin-top: 8px;
}

