.sites__div--margins {
	width: 400px;
}
.sites__div--margins > button:nth-child(n) {
	margin: 20px 15px 10px 15px;
}

.ReviewSitesAnalytics__page--header {
	display: inline-block;
	width: 100%;
}
.ReviewSites--datepicker {
	margin-bottom: 7px;
}
.ReviewSitesAnalytics__form-datepicker {
	margin-left: 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
}
.ReviewSites__text--capitalize {
	text-transform: capitalize;
}
.ManageReviewSites__header {
	display: flex;
}
.SortButtons__li--buttons {
	border-radius: 5px;
	flex: 0 420px;
	box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03), 0 1px 2px rgba(102, 119, 136, 0.3);
	padding: 18px;
	height: 55px;
	list-style-type: none;
	cursor: pointer;
	margin: 1em 1em 1.4em 1em;
	user-select: none;
}

.SortButtons__li--buttons:first-child {
	margin-top: 0;
}

.SortButtons__li--buttons:active {
	box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03), 0 1px 2px rgba(102, 119, 136, 0.3);
	transform: translateY(2px);
}

.SortableContainer__helperClass {
	box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03), 0 1px 2px rgba(102, 119, 136, 0.3);
	transform: translateY(2px);
	opacity: 0.5;
	color: #ffffff;
	padding: 17px 10px 10px 18px;
}

.ReviewSites__header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 0 30px 30px 30px;
}

.ReviewSites__header__message {
	display: flex;
	align-items: center;
	margin-bottom: 0;
}

.ReviewSites__header__message svg {
	position: relative;
	top: -2px;
	margin-right: 5px;
}

.ReviewSites__header__actions {
	display: flex;
	align-items: center;
	position: relative;
}

.ReviewSites__block--edit {
	flex: 0;
	position: relative;
	top: -5px;
}

.ReviewSites__block {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.ReviewSites__sites-list {
	padding-left: 30px;
}

.ReviewSites__color-config {
	width: 100px;
}

@media (max-width: 767px) {
	.ReviewSitesAnalytics__form-datepicker {
		display: inline-block;
	}
	.ReviewSitesAnalytics__page--header {
		margin-bottom: 15px;
	}
	.ReviewSitesAnalytics__location--field {
		margin-top: -35px;
	}
	.InputFromTo input {
		border-style: solid;
		border-color: lightgray;
		border-width: 1px;
		margin-bottom: 5px;
	}
}
@media (max-width: 320px) {
	.ReviewSites__buttons--condensed {
		float: none !important;
		display: block;
		width: 100px;
		margin-top: 10px;
	}
	.SortButtons__li--buttons {
		height: 80px;
	}
}
