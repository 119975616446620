.dropdown__options {
	position: absolute;
	min-width: 230px;
	max-width: 600px;
	max-height: 800px;
	background-color: white;
	border-radius: 5px;
	padding: 15px 0 15px 0;
	overflow: scroll;
	color: #333;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	z-index: 1001;
}

.dropdown__options::-webkit-scrollbar {
	display: none;
}
