
.gif-carousel__container{
    overflow: auto hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;

    min-height: 220px;
}

.gif-carousel__load-more-edge{
    width: 200px;
    display: inline-block;
    vertical-align: top;
}
