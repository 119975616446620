.color-swatch {
	display: flex;
	flex-flow: row wrap;
	justify-content: flex-start;
	align-items: center;

	margin-bottom: 20px;
}

.color-swatch__item {
	width: 20px;
	height: 20px;

	border-radius: 20px;

	margin: 5px 5px 15px 5px;;
}

.color-swatch__item:hover {
	cursor: pointer;
}

.color-swatch__item--selected {
	border-width: 2px;
	border-style: solid;
	border-color: white;

	outline-width: 2px;;
	outline-style: solid;
}