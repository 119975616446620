/* dcc = Download contact conversation */
.dcc {
	width: 350px;
	min-width: 350px;
}
.dcc__mediums {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	width: 330px;
}

.dcc__all-time {
	margin-top: 10px;
	padding-top: 5px;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dcc__mediums__medium {
	display: flex;
	align-items: center;
	width: 110px;
}

.dcc__dates {
	width: 150px;
	padding-left: 5px;
}
