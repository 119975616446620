.mb-result {
    height: 90px;
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid #F1F1F1;
    border-left: 3px solid #ffffff
}

.mb-result--active {
    border-left: 3px solid #60A9FF !important;
}

.mb-result:hover {
    background-color: #F6F6F9;
    border-left: 3px solid #F6F6F9;
    cursor: pointer;
}

.mb-result-top {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    white-space: nowrap;
    overflow: hidden;
}

.mb-result-name {
    font-size: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mb-result-date {
    margin-left: auto;
    font-size: 12px;
}

.mb-result-medium {
    font-size: 9px;
    font-weight: bold;

    padding: 5px 8px 3px 8px;
    
    margin-bottom: 5px;
    margin-left: 8px;
    margin-right: 8px;

    border-style: solid;
    border-radius: 8px;
    border-width: 1px;
}

.mb-result-medium--sms {
    border-color: #27AE60;
    color: #27AE60;
}

.mb-result-medium--facebook {
    border-color: #0084ff;
    color: #0084ff;
}

.mb-result-medium--email {
    border-color: #ff6b81;
    color: #ff6b81;

    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mb-result-medium--google {
    border-color: #0084ff;
    color: #0084ff;
}

.mb-result-medium--instagram {

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;

    background-size: 100%;
    background-repeat: repeat;

    border-color: #dc2743;
    background-image: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 

}

.mb-result-medium--secure {
    border-color: #6f00ff;
    color: #6f00ff;
}

.mb-result-inbox {
    height: 20px;
    font-size: 11px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 8px;
    padding: 5px 8px;
    color: #676a6c;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 5px;
    background-color: #f1f2f6;
    white-space: nowrap;
}

.mb-result-bottom {
    margin-top: 10px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
