.twilio-a2p {
	margin-left: 30px;
	margin-bottom: 30px;
}

.twilio-a2p__spinner-container {
	display: inline-block;
	margin-right: 10px;
}

.twilio-a2p__metadata {
	height: 300px;
	margin-top: 20px;
	font-size: 8px;
}

.twilio-a2p__actions {
	margin-left: auto;
	display: flex;
	flex-flow: row nowrap;
}

.twilio-a2p__metadata_button:hover {
	cursor: pointer;
}
