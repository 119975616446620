/* fm-modal = forward-message-modal */

.fm-modal {    
    width: 100%;
}

.fm-modal__search-input {
    margin: 0 0 15px 0;
}

.fm-modal__convos {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    
    height: 250px;
    overflow-y: scroll;
}

.fm-modal__convos__header {
    display: flex;
    justify-content: space-between;
    
    padding: 6px 8px 0 8px;
    margin-bottom: 4px;
    
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
}

.fm-modal__convos__header:hover {
    background-color: #F6F6F9;
    cursor: pointer;
}

.fm-modal__convos__list {
    margin-bottom: 15px;
}

.fm-modal__convos__list__item {
    padding: 6px 8px
}

.fm-modal__convos__list__item:hover {
    background-color: #F6F6F9;
    cursor: pointer;
}


.fm-modal__text {
    width: 40%;
}

.fm-modal__confirm {
    position: relative;
}

.fm-modal__confirm__header {
    margin-bottom: 15px;

    font-size: 15px;
    text-align: center;
}

.fm-modal__confirm__message {
    display: flex;
    flex-flow: column nowrap;

    margin: auto;
    padding: 8px;

    width: fit-content;
    border-radius: 8px;
    border: 2px solid #F6F6F9;
    background-color: #F6F6F9;
}

.fm-modal__confirm__note-message {
    margin-top: 15px;
}

.fm-modal__confirm__buttons {
    display: flex;
    justify-content: space-between;

    margin-top: 15px;
    
    width: 100%;
}