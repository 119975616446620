.auto-replies {
	margin-left: 30px;
	margin-right: 30px;
}

.auto-replies__box {
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px;
}

.auto-replies__box--custom-replies {
	margin-top: 30px;
	margin-bottom: 50px;
}

.auto-replies__box__header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
}

.auto-replies__box__header__text,
.auto-replies__box__text {
	font-size: 24px;
}

.auto-replies__box__info__container {
	border-radius: 10px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 15px;
	margin-bottom: 15px;
}

.auto-replies__box__info {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.auto-replies__box__info__toggle {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-weight: 600;
}

.auto-replies__box__info__toggle__item {
	margin-right: 15px;
}

.auto-replies__box__info__toggle__text {
	margin-top: 4px;
}

.auto-replies__box__info__actions {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.auto-replies__reply {
	background-color: #edeff2;
	border-radius: 5px;
	padding: 10px;
	margin-top: 10px;
	margin-bottom: 5px;
}

.auto-replies__view-hours {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.auto-replies__modal__header {
	font-size: 24px;
}

.auto-replies__modal__during-hours {
	margin-top: 35px;
}

.auto-replies__modal__actions {
	align-self: flex-end;
	display: flex;
	margin-top: 30px;
}

.auto-replies__custom-hours-modal__input {
	width: 100%;
	margin-bottom: 5px;
}

.auto-replies__custom-hours-modal__toggle {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.auto-replies__custom-hours-modal__days {
	display: flex;
	align-items: center;
}

.auto-replies__custom-hours-modal__day {
	display: flex;
	align-items: center;
}

.auto-replies__custom-hours-modal__day__text {
	margin: 10px 15px 0 7px;
}

.auto-replies__custom-hours-modal__times {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 20px;
}

.auto-replies__custom-hours-modal__times__to {
	margin-right: 15px;
	margin-left: 15px;
	margin-top: 33px;
}

.auto-replies__custom-hours-modal__times__time {
	display: flex;
	flex-direction: column;
}

.auto-replies__custom-hours-modal__actions {
	align-self: flex-end;
	display: flex;
	margin-top: 30px;
}
