.landing-page-container {
	height: 100%;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.landing-page {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.landing-page--no-children {
	margin-bottom: 200px;
}

.landing-page__image{
	width: 300px;
	margin-right: 30px;
}

.landing-page__image > img{
	width: 100%;
	min-width: 150px;
}

.landing-page__content{
	width: 600px;
	max-width: 600px;
}

.landing-page__title{
	font-size: 48px;
}

.landing-page__beta{
	font-size: 12px;
	display: inline-block;
	vertical-align: super;
	color: #60a9ff;
}

.landing-page__text_content{
	font-size: 18px;
	max-width: 660px;
}

.landing-page__button{
	margin-top: 30px;
	padding-top: 2px;
	height: 40px;
	width: 130px;
	font-size: 16px;
	border-radius: 8px;
	color: white;
	background-color: #60A9FF;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	z-index: 1;
}

.landing-page__button:hover {
	cursor: pointer;
}

.landing-page__more {
	padding: 50px 0;
	display: flex;
	justify-content: flex-start;
}

.landing-page__block {
	font-size: 18px;
	width: 200px;
	margin: 0 10px;
	display: flex;
	flex-direction: column;
}

.landing-page__block__item {
	width: 200px;
	height: 132px;
	position: relative;
	
	align-items: center;
}

.landing-page__block__item__img {
	max-width: 140px;
	max-height: 132px;
	position: absolute;
	bottom: 0;
}

.landing-page__block__item__text {
	padding-top: 15px;
	text-align: left;
	
}

@media only screen and (max-width: 980px) {
	.landing-page__more {
		margin-left: -50px;
		width: 100%;
		flex-direction: column;
		align-items: center;
	}
	
	.landing-page__block  {
		width: 300px;
		flex-direction: row;
	}
	
	.landing-page__block__item__text  {
		width: 200px;
	}
	
	.landing-page__block__item__img {
		width: 150px;
	}
}

