.media-grid {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
}

.media-grid__load-more-button {
	align-self: center;
	font-size: 14px;
	padding: 6px 15px 4px 15px;
	border-radius: 50px;
	border: 2px solid #60a9ff;

	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
}

.media-grid__load-more-button:hover {
	border: 2px solid #60a9ff;
	background-color: #60a9ff;
	color: white;
	cursor: pointer;
}
