/* CSS talk bubble */
.talk-bubble {
	margin: 15px auto 15px auto;
	display: block;
	position: relative;
	max-width: 315px;
	width: auto;
	min-width: 150px;
	height: auto;
	background-color: #1ab394;
	color: #fff;
	border-radius: 20px;
}

/* Right triangle placed top left flush. */
.tri-right.border.left-top:before {
	content: " ";
	position: absolute;
	width: 0;
	height: 0;
	left: -40px;
	right: auto;
	top: -8px;
	bottom: auto;
	border: 32px solid;
}
.tri-right.left-top {
	content: " ";
	position: absolute;
	width: 0;
	height: 0;
	left: -20px;
	right: auto;
	top: 0px;
	bottom: auto;
	border: 22px solid;
}

/* Right triangle placed top right flush. */
.tri-right.border.right-top:before {
	content: " ";
	position: absolute;
	width: 0;
	height: 0;
	left: auto;
	right: -40px;
	top: -8px;
	bottom: auto;
	border: 32px solid;
	border-color: #666 transparent transparent transparent;
}
.tri-right.right-top {
	content: " ";
	position: absolute;
	width: 0;
	height: 0;
	left: auto;
	right: -20px;
	top: 0px;
	bottom: auto;
	border: 20px solid;
}

/* talk bubble contents */
.talktext {
	padding: 1em;
	text-align: left;
	line-height: 1.65em;
	word-wrap: break-word;
}
.talktext p {
	/* remove webkit p margins */
	-webkit-margin-before: 0em;
	-webkit-margin-after: 0em;
}
