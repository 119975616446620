/* cem = calendar-event-modal */

.cem {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	
	width: 500px;
	min-height: 700px;
}

.cem__form {
	flex-grow: 1;
	width: 100%;
}

.cem__form__actions {
	margin-top: auto;
	
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;
	justify-content: flex-end;
}