.workflow {
	display: flex;
	flex-direction: row;
	width: 100%;
	margin: 0 2px 24px 0;
	padding: 15px 0 15px 14px;
	border-radius: 8px;
	background-color: #f6f6f9;

	border-left-width: 2px;
	border-left-style: solid;
}

.workflow--not-active {
	opacity: 0.7;
}

.workflow__toggle {
	cursor: pointer;
	display: flex;
	align-items: center;
	padding: 10px 14px;
	margin-right: 10px;
}

.workflow__toggle:hover {
	background-color: #e8ecf1;
	border-radius: 5px;
}

.workflow__content {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.workflow__content__header {
	height: 36px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.workflow__content__header__left {
	display: flex;
	align-items: center;
	flex-direction: row;
}

.workflow__content__header__left__label {
	margin-left: 8px;
	margin-right: 8px;
}

.workflow__content__header__left__name {
	width: 175px;
}

.workflow__content__header__left--warning {
	color: red;
}

.workflow__content__header__right {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	position: relative;
}

.workflow__content__header__right__color {
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.workflow__content__header__right__control {
	margin-right: 15px;
}

.workflow__content__header__right .react-switch-bg {
	margin-right: 5px !important;
}

.workflow__icon {
	padding: 10px 14px;
	margin-right: 8px;
}

.workflow__icon:hover {
	background-color: #e8ecf1;
	border-radius: 8px;
	cursor: pointer;
}

.workflow__content__more {
	display: flex;
	width: 100%;
	margin-top: 20px;
}

.workflow__content__more__section {
	flex: 1;
	margin-right: 25px;
	margin-bottom: 45px;
}

.workflow__content__more__section--conditions {
	flex: 2;
}

.workflow__content__more__section__header {
	font-size: 18px;
	margin-left: 3px;
	margin-bottom: 15px;
}

.workflow__content__more__section__subheader {
	font-size: 18px;
	margin-left: 3px;
	margin-top: 30px;
}

.workflow__content__more__section__header--action {
	height: 100%;
}

.workflow__content__more__section__text {
	margin-top: 20px;
	margin-left: 5px;
	margin-right: 5px;
}

.workflow__content__more__section__action-fields {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.workflow__content__more__section__action-config {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.workflow__content__more__section__button {
	display: flex;
	justify-content: flex-end;
}

.workflow__select {
	width: 100%;
}

.workflow__content__more__conditions {
	display: flex;
	flex-direction: column;
	max-height: 460px;
	overflow-x: hidden;
	width: 100%;
	padding: 0 5px 5px 5px;
}

.workflow__content__more__conditions__add {
	position: relative;
	color: #a9ceff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 20px;
	min-width: 40px;
}

.workflow__content__more__condition {
	display: flex;
	flex-direction: column;
}

.workflow__confirm-save-alert {
	font-size: 14px;
}

.workflow__modal__workflow-info {
	width: 100%;
}

.workflow__modal__content {
	display: flex;
	flex-flow: row wrap;
	align-items: flex-start;
	justify-content: flex-start;
	min-height: 250px;
	width: 760px;
	max-width: 760px;
	overflow-y: scroll;
	padding-bottom: 25px;
}

.workflow__modal__content__item {
	width: 230px;
	margin-right: 20px;
}

.workflow__modal__content__item .Common__input {
	height: 38px;
}

.workflow__modal__content__item .modal__field.dh-tip {
	position: relative;
}

.workflow__modal__content__item__tooltip {
	max-width: 300px;
}

.workflow__modal__confirm-save-alert {
	font-size: 14px;
}

/* waf = workflow-action-field */

.waf {
	width: 230px;
	margin-right: 20px;
}

.waf__field {
	display: flex;
	height: 18px;
}

.waf__field__required {
	margin-top: -4px;
	margin-right: 3px;
	font-size: 24px;
	color: #ed5565;
}

.waf__tooltip {
	max-width: 300px;
}

/* wac = workflow-action-config */

.wac {
	width: 230px;
	margin-right: 20px;
}

.wac__field {
	position: relative;
	display: flex;
	align-items: center;
	height: 18px;
}

.wac__field__required {
	margin-top: -4px;
	margin-right: 3px;
	font-size: 24px;
	color: #ed5565;
}

.wac__tooltip {
	max-width: 300px;
}

.wac__object {
	margin-top: 25px;
	width: 260px;
}

.wac__object__field {
	display: flex;
	align-items: center;
	width: 100%;
}

.wac__object__field__input {
	margin-right: 10px;
	margin-bottom: 10px;
}

.wac__object__field__remove {
	position: relative;
	width: 30px;
	margin-bottom: 10px;
}

.wac__field__action {
	margin-left: 15px;
	margin-top: 0;
	margin-bottom: 5px;
}
