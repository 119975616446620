
.mb-thread-list__tcsd {
    display: flex;
    align-items: center;
    margin-right: 20px;
    padding: 5px 0px 5px 10px;
    font-size: 15px;
    font-style: italic;
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
}

.mb-thread-list__tcsd__see-all {
    font-weight: 600;
    color: #60A9FF;
    margin-left: 5px;
    margin-right: 15px;
}

.mb-thread-list__tcsd__see-all:hover {
    cursor: pointer;
    opacity: 0.7;
}