/* rmm = reengagement-manage-modal */

.rmm__conditions {
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
}

.rmm__condition {
    padding: 12px 12px 12px 12px;
}

.rmm__condition__condition__type__input {
    width: 100%;
    margin-bottom: 0;
}

.rmm__condition__condition__type__input__select {
    width: 100%;
}

.rmm__condition .condition__type {
    align-items: flex-start;
}

.rmm__conditions__condition__parameter__name {
    width: 164px;
}

.rmm__conditions__condition__parameter__name__input {
    width: 120px;
}

.rmm__conditions__condition__parameter__operator__select {
    width: 80px;
}

.rmm__conditions__condition__parameter__value__select {
	width: 100%;
}

.rmm__filter{
    margin-bottom: 20px;
}

.rmm__filter__title{
    margin-bottom: 5px;
    font-weight: 600;
}
