.loading-button__spinner--center {
	position: relative;
	left: 43%;
	transform: translate(0%, -100%);
	min-width: 50px;
}

.mb-button:hover .loading-button__spinner--center .Spinners__3circle-fade {
	color: #ffffff !important;
}

.mb-button--cancel .loading-button__spinner--center .Spinners__3circle-fade {
	color: #e74c3c !important;
}

.mb-button--cancel:hover .loading-button__spinner--center .Spinners__3circle-fade {
	color: #ffffff !important;
}
