.DHSearchInput__container {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	margin: 10px 0;
	border-radius: 50px;
	background-color: #f1f2f6;
	border-width: 0px;
	padding-left: 20px;
	padding-right: 20px;
	height: 40px;
}
.DHSearchInput__sub-container {
	display: flex;
}
.DHSearchInput__input {
	background-color: #f1f2f6;
	border-width: 0px;
	outline: none;
	font-size: 14px;
	width: 100%;
	padding-right: 10px;
}
.DHSearchInput__clear {
	margin-left: auto;
	margin-top: 2px;
	color: #8c9092;
	cursor: pointer;
}
