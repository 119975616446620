/* scl === showcase list */

.scl {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 100%;

	padding-bottom: 20px;
	border-bottom: 1px solid #f5f5f5;
}

.scl--no-border {
	border: none;
}

.scl__header {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
	margin-bottom: 10px;
}

.scl__header__title {
	font-weight: 900;
	display: flex;
	font-size: 14px;
}

.scl__body {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.scl__body__options {
	width: 500px;
	min-width: 400px;
}

.scl__options {
	margin-right: 50px;
	max-width: 500px;
	width: 100%;
}

.scl__options__field {
	/* width: 440px; */
	width: 100%;

	font-size: 14px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	padding-top: 15px;
	padding-bottom: 15px;

	padding-left: 10px;
	border-radius: 8px;
}

.scl__options__field--selected {
	background-color: rgba(96, 169, 255, 0.2);
	transition: background 0.2s, border 0.2s;
}

.scl__options__field--orderable {
	border-radius: 5px;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.scl__options__field--orderable:hover {
	cursor: move;
	background-color: rgba(96, 169, 255, 0.2);
	transition: background 0.2s, border 0.2s;
}

.scl__options__field__data {
	flex: 1;
	margin-right: 20px;
}

.scl__options__field__data__title {
	font-weight: 900;
	display: inline-block;
}

.scl__options__field__data__subtitle {
	display: inline-block;
	font-weight: 300;
	margin-left: 5px;
}

.scl__options__field__data__description {
	margin-top: 5px;
}

.scl__options__field__data__footer {
	margin-top: 5px;
	font-size: 10px;
	font-style: italic;
}

.scl__options__field__input {
	margin-left: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
}

.scl__options__field__actions {
	display: flex;
	align-items: center;
	position: relative;
}

.scl__options__field__actions svg {
	position: relative;
	top: 2px;
}
