.a2p-modal {
	width: 100%;
	height: 580px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.a2p-modal__body__name {
	margin-top: 0;
}

.a2p-modal__body__checkbox {
	margin-top: 0;
}

.a2p-modal__body__field_error_text {
	margin-top: -15px;
	margin-bottom: 10px;
	color: red;
}

.a2p-modal__body__checkboxes_container {
	padding: 20px;
}

.a2p-modal__body__subusecase_checkboxes__checkbox:hover {
	cursor: pointer;
}

.a2p-modal__body__subusecase_checkboxes__label {
	padding-left: 30px;
}

.a2p-modal__body__subusecase_checkboxes {
	margin-bottom: 30px;
}