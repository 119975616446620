.message-pins {
    width: 400px;
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;
}

.message-pins__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-size: 14px;
}

.message-pins__list__item {
    display: flex;
    align-items: center;
}

.message-pins__list__item__message {
    flex: 1;
    border-radius: 8px;
    border: 2px solid #F6F6F9;
    background-color: #F6F6F9;
    padding: 8px;
    margin-bottom: 10px;
    margin-right: 10px;
    word-break: break-all;
    width: 318px;
    max-width: 318px;
}

.message-pins__list__item__message:hover {
    cursor: pointer;
    opacity: 0.7;
}

.message-pins__list__item__message__content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.message-pins__list__item__message__sent-by {
    margin-top: 6px;
    text-align: right;
    font-size: 10px;
    display: flex;
    justify-content: flex-end;
}

.message-pins__list__item__message__sent-by--out {
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
}

.message-pins__list__item__message__sent-by__name {
    max-width: 150px;
    margin: 0 5px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.message-pins__list__none {
    margin: auto;
}