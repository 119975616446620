.keywords__list__record--words {
	display: flex;
}

.keywords__list__record--word {
	margin-right: 5px;
	padding: 3px;
	padding-bottom: 5px;
}

.keywords__list__record--actions {
	display: flex;
}

.keywords__limit-message {
	margin-left: 40px;
	margin-bottom: 20px;
	font-size: 16px;
}

.keywords_limit-message--reached {
	color: #ed5565;
	position: absolute;
}

.keywords_limit-message--reached svg {
	position: relative;
	top: 0;
	left: 5px;
	width: 18px;
	height: 18px;
}

.keywords-modal__body {
	width: 100%;
}

.keywords-modal__body__words {
	display: flex;
	flex-direction: column;
	margin-top: 5px;
	max-height: 250px;
	overflow-x: hidden;
	overflow-y: auto;
}

.keywords-modal__body__word {
	margin-top: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border: 1px solid rgba(0, 0, 0, 0.06);
	border-radius: 8px;
	padding: 10px;
	background-color: #f6f6f9;
}

.keywords-modal__body__word svg {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.keywords-modal__body__word svg:hover {
	opacity: 0.6;
}

.keywords-modal__body__response {
	margin-top: 15px;
}

.keywords-modal__input {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.keywords-modal__input .mb-button {
	margin-top: 0;
	margin-left: 5px;
}

.keywords-modal__body__save {
	display: flex;
	justify-content: flex-end;
	margin-top: 15px;
}

.keywords-modal__body__status {
	margin-top: 15px;
	display: flex;
	font-weight: 700;
	align-items: center;
}

.keywords-modal__body__status__text {
	margin-right: 20px;
	margin-bottom: 5px;
}
