/* Related to the giphy selecting component */
.mb-reply-message {
    position: fixed;
    z-index: 1000;
    bottom: 0;
    background-color: white;
    margin: 10px 20px 65px 30px;
    border-radius: 8px;
    padding: 15px;
    overflow: auto;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    overflow: hidden;

    width: 600px;
    min-height: 48px;
    max-height: 150px;

    border-left: 3px solid #60A9FF;
    transition: width 500ms, height 250ms;
}

.mb-reply-message--minimized {
    height: 48px;
    width: 350px;
    padding: 15px;
    opacity: 0.7 !important;
    transition: width 500ms, height 250ms;
}

.mb-reply-message--minimized .mb-reply-message__text {
    display: none;
}

.mb-reply-message__container-actions {
    float: right;
    height: 100%;
    display: flex;
}

.mb-reply-message__container-actions__action {
    cursor: pointer;
    margin-left: 8px;
}

.mb-reply-message__container-actions__action:hover {
    opacity: 0.8;
}


.mb-reply-message__container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
}

.mb-reply-message__text-content{
    align-self: flex-start;
    width: 100%;
}

.mb-reply-message__image-content{
    align-self: flex-start;
    max-width: 125px;
    max-height: 72px;
    margin-right: 10px;
    border: 1px solid grey;
    border-radius: 5px;
    overflow: hidden;
}

.mb-reply-message__image-content img{
    width: 100%;
    height: 100%;

    cursor: inherit;
    border-radius: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.mb-reply-message__title {
    font-size: 15px;
    font-weight: 900;
    padding-bottom: 5px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mb-reply-message__text{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3;
    -webkit-box-orient: vertical;
}

.mb-reply-message__text__emoji{
    padding-right: 10px;
}
