.dh-filters {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	font-size: 16px;
	margin-bottom: 10px;
	margin-left: 40px;
}

.dh-filters__items {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	overflow: auto;
}

.dh-filters__title {
	opacity: 1 !important;
	font-weight: 600;
	margin-right: 20px;
}

.dh-filters__items--selected {
	color: #60a9ff;
	opacity: 1 !important;
}

.dh-filters__items--selected.dh-filters__item .dh-filters__item--text {
	text-decoration-line: underline;
}

.dh-filters__items > div {
	margin-right: 30px;
	opacity: 0.7;
	display: flex;
}

.dh-filters__items > div:hover {
	opacity: 1;
	cursor: pointer;
}

.dh-filters__badge {
	font-size: 11px;
	min-width: 20px;
	width: auto;
	height: 20px;
	font-weight: bold;
	margin-left: 5px;
	background-color: #ec4758;
	color: white;
	padding: 3px 2px 0px 2px;
	border-radius: 50px;
	align-self: baseline;
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
}
