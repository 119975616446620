.dh-login-bg {
	background: url(https://cdn.demandhub.co/img/misc/dh-login-bg.png);
	background-color: #f3f3f4;
}

.login {
	padding: 16px 45px 5px;
	border-radius: 20px;
	width: 360px;
	z-index: 100;
	margin: 8% auto 50px;
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.login > div {
	margin-bottom: 15px;
}

.login > input {
	margin-bottom: 10px;
}

.login__header {
	font-size: 16px;
}

.login__loading {
	position: relative;
	height: 258px;
}

.login__login-option-btn {
	border-radius: 8px;
}

.login__bottom-controls {
	margin-top: 26px;
	margin-bottom: 10px !important;

	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
}

.login__forgot-password__link {
	color: #aaaaaa;
	font-size: 14px;
	margin-right: 15px;
}

.login__forgot-password__link:hover {
	color: #60a9ff;
	font-size: 14px;
}

.login__copyright {
	width: 100%;
	position: absolute;
	bottom: 10px;
	display: flex;
	justify-content: center;
}

.login__copyright__text {
	color: #aaaaaa;
	font-size: 10px;
}

.login__divider {
	margin-top: 15px;
	margin-bottom: 20px !important;
	display: flex;
	align-items: center;
	text-align: center;
}

.login__divider::after,
.login__divider::before {
	content: "";
	border-top: 1px solid #e0e0e0;
	flex: 1;
}

.login__form {
	display: flex;
	flex-direction: column;
	align-self: center;
}

.login__form__email {
	margin-bottom: 10px;
}

.login__form,
.login__sign-in-options {
	padding: 0 20px;
}

.login__form #password {
	margin-bottom: 15px;
}

.login__sign-in-options .mb-button {
	text-align: center;
	margin: 10px 0 0 0;
}

.login__sign-in-options .fa {
	font-size: 16px;
	margin-right: 10px;
}

.login__logo__img {
	width: 58%;
	height: auto;
	max-width: 58%;
}

.login__login-btn {
	padding: 6px 40px 6px;
}

.login__login-btn:hover {
	color: white;
	background-color: #60a9ff;
	cursor: pointer;
	transition: background 0.2s, border 0.2s, color 0.2s;
}

@media only screen and (max-width: 420px) {
	.login {
		width: 85%;
		padding-bottom: 50px;
	}

	.login__logo__img {
		width: 40%;
		max-width: 40%;
	}
}

@media only screen and (max-width: 380px) {
	.login {
		padding-left: 15px;
		padding-right: 15px;
	}
}
