
.teamchat-discovery{
    width: 100%;
    height: 300px;
    padding-right: 10px;
    padding-left: 10px;
    font-family: 'AvenirNext';
    margin-top: 10px;
}

.teamchat-discovery__wrapper{
    padding: 16px;
    border-radius: 10px;
    position: relative;

    background: white;
}

.teamchat-discovery__title{
    font-size: 20px;
}

.teamchat-discovery__mini_title{
    font-size: 10px;
}

.teamchat-discovery__subtitle{
    font-size: 12px;
    color: #4a90e2;
}

.teamchat-discovery__text{
    color: #333;
    font-size: 20px;
    padding-top: 2px;
}

.teamchat-discovery__icon{
    color: #4a90e2;
    position: absolute;
    right: 10px;
    top: 10px;
    opacity: 0.8;
}

.teamchat-discovery__divider{
    border-top: solid 1px #4a4a4a14;
    margin-top: 5px;
    margin-bottom: 5px;
}

.teamchat-discovery__features{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
}

.teamchat-discovery__feature{
    margin-top:10px;
    margin-bottom:10px;
    width: 100%;
}

.teamchat-discovery__feature__icon{
    display: inline-block;
    width: 100%;
    text-align: center;
}

.teamchat-discovery__feature__description{
    display: inline-block;
    font-size: 12px;
    vertical-align: middle;
    width: 100%;
    text-align: center;
}

.teamchat-discovery__action{
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2px;
    height: 33px;
    width: 100px;
    font-size: 12px;
    border-radius: 8px;
    color: white;
    background-color: #4a90e2;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;

    opacity: 0.8;
}

.teamchat-discovery__action:hover{
    cursor: pointer;
    opacity: 1;
}