/* bwbs === booking-widget-booking-slots */

.bwbs {
	font-size: 14px;
	margin: 0px 20px;
}

.bwbs__days {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
}

.bwbs__days__day {
	width: 80px;
	margin: 5px 5px 5px 5px;
	font-size: 15px;
	color: #5a5a5a;
}

.bwbs__bucket {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	font-size: 12px;
}

.bwbs__bucket__name {
	width: 80px;
	margin: 5px 5px 5px 5px;
	font-size: 15px;
	color: #5a5a5a;
}

.bwbs__bucket__day {
	width: 80px;
	margin: 5px 5px 5px 5px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.bwbs__bucket__day__text {
	text-align: center;
}

.bwbs__bucket__day__btn {
	border: 1px solid rgba(0, 0, 0, 0.1);
	border-radius: 10px;
	padding: 8px 3px;
	color: #60a9ff;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
}

.bwbs__bucket__day__btn:hover {
	border-color: rgba(0, 0, 0, 0.2);
	opacity: 0.7;
	cursor: pointer;
	transition: ease-in-out 0.2s;
}

.bwbs__bucket__day__btn--closed {
	color: #5a5a5a;
}

.bwbs__note-container {
	padding: 25px;
	background: aliceblue;
	margin-top: 20px;
}

.bwbs__note__title {
	font-size: 16px;
}

.bwbs__note__day-status {
	font-style: italic;
}
