.prd {
    font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    background-color: white;
    width: calc(100vw);
    height: calc(100vh - 99px);
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    margin-left: -15px;
    overflow: scroll;
}

.prd-content {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.prd-title {
    font-size: 36px;
    margin-left: 40px;
    margin-top: 20px;
    margin-bottom: 15px;
}

.prd-details {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 40px;
    margin-bottom: 40px;
    padding: 20px;
    border: 1px solid #f5f5f5;
    /* width: calc(100vw - 300px); */
    border-radius: 8px;
}

.prd-details-title {
    font-size: 24px;
}

.prd-details-item {
    margin-top: 20px;
    font-size: 14px;
}

.prd-column {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    flex-grow: 1;
    min-width: 200px;
}

.prd-timeline {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 40px;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    width: calc(100% - 100px);
    margin-bottom: 40px;
    overflow: scroll;
}

.prd-timeline-title {
    font-size: 24px;
    padding: 20px;
}

.prd-timeline-details {
    margin-bottom: 20px;
    padding-left: 80px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    height: 115px;
}

.prd-timeline-details-line {
    margin-top: 4px;
    width: 150px;
    height: 2px;
    background-color: #F6F6F9;
}

.prd-timeline-details-event {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: #60A9FF;
}

.prd-timeline-details-event-name {
    position: relative;
    right: 45px;
    top: 20px;
    width: 100px;
    padding-bottom: 20px;
    text-align: center;
    text-transform: capitalize;
}

.prd-invoice-items {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 40px;
    border: 1px solid #f5f5f5;
    border-radius: 8px;
    width: calc(100% - 100px);
    margin-bottom: 40px;
    overflow: scroll;
}

.prd-invoice-items__title {
    font-size: 24px;
    padding: 20px;
} 

.prd-invoice-items__list {
    width: 100%;
}

.prd-invoice-items__list__record {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-start;
    height: 40px;
    font-size: 16px;
    padding-left: 20px;
    width: 100%;
}

.prd-invoice-items__list__record--odd {
    background-color: #F6F6F9;
}

.prd-invoice-items__list__record--header {
    height: 45px !important;
    border-top: 1px solid #f5f5f5;
}

.prd-invoice-items__list__record__name {
    width: 80%;
}

.prd-invoice-items__list__record__amount {
    width: 20%;
}

.prd-message {
    border-radius: 8px;
    border: 2px solid #F6F6F9;
    background-color: #F6F6F9;
    padding: 8px;
    display: flex;
    flex-flow: column nowrap;
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
    font-size: 14px;
    max-width: 400px;
}

.prd-actions {
    align-self: flex-end;
    margin-right: 10px;
}

.prd-action-button {
    align-self: flex-end;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 7px;
    font-size: 14px;
    padding: 6px 10px 4px 10px;
    border-radius: 50px;
    align-self: flex-end;
    border: 2px solid #60A9FF;
}

.prd-action-button:hover {
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
    cursor: pointer;
}