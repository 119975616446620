.dh-locations-map {
	width: 100%;
	height: 100%;
	position: relative;
}

.dh-locations-map__menu {
	position: absolute;
	left: 0;
	right: 0;
	margin: auto;
	margin-top: 10px;
	z-index: 1;
	width: 320px;
	background-color: rgba(255, 255, 255, 1);
	border-radius: 8px;
	padding: 8px;
	transition: all 0.25s;
	-webkit-transition: all 0.25s;
}

.dh-locations-map__menu__options {
	padding-top: 10px;
	padding-bottom: 10px;
}

.dh-locations-map__menu__header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.dh-locations-map__menu__header__text {
	font-size: 18px;
	display: flex;
	align-items: center;
}

.dh-locations-map__menu__header__buttons {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.dh-locations-map__menu__header__buttons__button {
	height: 28px;
	width: 28px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dh-locations-map__menu__header__buttons__button:hover {
	cursor: pointer;
	background-color: #f3f3f4;
}

.dh-locations-map__menu__search {
	margin-left: 30px;
	margin-bottom: 20px;
}

.dh-locations-map__menu__options__filter {
	width: 100%;
	margin-top: 10px;
}

.dh-locations-map__menu__count {
	position: absolute;
	bottom: 1px;
	left: 10px;
	font-size: 10px;
}

.dh-map {
	height: 100%;
	width: 100%;
}

.dh-map__marker {
	background-color: #60a9ff;
	width: 20px;
	height: 20px;
	border-radius: 50%;
}

.dh-map__marker:hover {
	opacity: 0.8;
}

.dh-map__info-window {
	font-size: 14px;
	min-width: 200px;
}

.dh-map__info-window__name {
	font-weight: 600;
	max-width: 300px;
	text-overflow: ellipsis;
}

.dh-map__info-window__item {
	margin-top: 5px;
}

.dh-map__info-window__item svg {
	position: relative;
	top: 2px;
	margin-left: 3px;
}
