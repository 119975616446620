.send-review-invites__button {
	text-align: center;
	overflow: hidden;
	line-height: 26px;
	text-overflow: clip;
	max-width: 169px;
	height: 34px;
}

.send-review-invites__button__text {
	position: relative;
	top: -3px;
}