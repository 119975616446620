.users__container--header {
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-ms-flex-line-pack: justify;
	align-content: space-between;
}

.users__header {
	width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	padding: 0 0 15px 30px;
}

.users__header__actions {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 100%;
	margin-bottom: auto;
	padding-right: 15px;
}

.users__search {
	margin: 0 30px 30px 0;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}

.users__buttons--header-align {
	margin-top: 18px;
	margin-left: auto;
	text-align: right;
}

.users__actions {
	display: flex;
}

.users__actions div {
	margin-right: 5px;
}

.users__reassign-alert {
	margin-bottom: 40px;
}
