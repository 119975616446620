/* fmll = feature matrix location list */

.fmll {
	width: 100%;
	height: 100%;
}

.fmll__search {
	width: 100%;
	padding: 0 30px 10px 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: baseline;
	position: relative;
}

.fmll__search__section {
	padding-left: 5px;
}

.fmll__search__section .dh-search {
	width: 250px;
}

.fmll__search__section__count {
	text-align: right;
	padding-top: 3px;
	padding-right: 8px;
}

.fmll__search__actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.fmll__filter-data {
	padding-left: 40px;
	margin-bottom: 15px;
}

.fmll__filter-data .badge {
	margin-top: 2px;
	margin-bottom: 2px;
	margin-right: 5px;
}

.fmll__filter-data__header {
	font-size: 16px;
	font-weight: 600;
}

.fmll .dh-list {
	height: 100%;
}

/* fmll__sm = fmll__side-modal */
.fmll__sm__section {
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.fmll__sm__columns__column {
	margin-top: 5px;
	margin-bottom: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: nowrap;
	margin-bottom: 10px;
}

.fmll__sm__header {
	padding: 10px 0;
	width: 100%;
	display: flex;
	justify-content: space-between;

	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+/Edge */
	user-select: none; /* Standard */
}

.fmll__sm__header:hover {
	opacity: 0.7;
}

.fmll__sm__columns__column__checkbox {
	height: 19px;
	margin-right: 7px;
}

.fmll__sm__columns__column__label {
	display: flex;
	align-items: center;
	height: 19px;
}

.fmll__sm__columns__column__select {
	margin-left: auto;
	width: 120px;
	height: 26px;
}

.fmll__sm__columns__column__select > div {
	height: 26px;
	display: flex;
	align-items: center;
}
