.ReviewResponse__template--dropdown {
	width: 250px;
	margin-left: -180px;
	z-index: 1000;
	padding: 0;
}
@media (min-width: 360px) {
	.ReviewResponse__template--dropdown {
		width: 250px;
		margin-left: -210px;
	}
}
@media (min-width: 528px) {
	.ReviewResponse__template--dropdown {
		width: 450px;
		margin-left: -410px;
	}
}
@media (min-width: 585px) {
	.ReviewResponse__template--dropdown {
		width: 500px;
		margin-left: -460px;
	}
}
@media (min-width: 768px) {
	.ReviewResponse__template--dropdown {
		width: 500px;
		margin-left: -390px;
	}
}
@media (min-width: 1110px) {
	.ReviewResponse__template--dropdown {
		transform: translate(0%, -2%);
		width: 664px;
		max-height: 300px;
		overflow: auto;
		margin-left: -555px;
	}
}
.ReviewResponse__template--dropdown > li > a {
	margin: 0;
}
.ReviewResponse__template--dropdown > li > a:hover {
	margin: 0;
	background-color: #f1f2f6;
}
.ReviewResponse__template--dropdown > li {
	padding: 1.6% 0em;
}
.ReviewResponse__template--dropdown > li:not(:first-child):hover {
	background-color: #f1f2f6;
}

.review-responding {
	display: flex;
	flex-direction: column;
	margin-top: 15px;
}

.review-responding__textarea {
	width: 100%;
	height: 100px;
	margin-top: 10px;
	resize: none;
	padding: 15px;
	border-radius: 10px;
	margin-bottom: 20px;
}

.review-responding__container--pad-left {
	margin-left: 40px;
}

.review-responding__button_text {
	margin-left: 5px;
	font-size: 12px;
}

.review-responding__controls {
	display: flex;
	flex-direction: row;
	align-items: center;

	margin-top: 10px;
	margin-bottom: 20px;

	align-self: flex-end;
}
