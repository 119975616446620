.dropdown-option__item {
	width: 100%;
	height: 40px;
	font-size: 15px;
	margin-bottom: 5px;
	padding-right: 20px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
}

.dropdown-option__item--tall {
	height: 60px !important;
}

.dropdown-option__item__icon {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 4px;
}

.dropdown-option__item__text {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.dropdown-option__item:hover {
	cursor: pointer;
	background-color: rgba(96, 169, 255, 0.2);
	transition: background 0.2s, border 0.2s;
}

.dropdown-option__item__text__title {
	margin-top: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dropdown-option__item__text__description {
	font-size: 11px;
}
