.rrule__frequency {
	margin-top: 20px;
	
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
}

.rrule__frequency__row {
	margin: 10px 10px 10px 0px;
	
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.rrule__frequency__row__select {
	min-width: 15px;
	min-height: 15px;
	
	border-radius: 30px;
	
	margin-left: 15px;
	margin-right: 15px;
	margin-bottom: 10px;
	
	background-color: white;
	border: 2px solid #60A9FF;
}

.rrule__frequency__row__select--active {
	background-color: #60A9FF;
	border: 2px solid #60A9FF;
}

.rrule__frequency__row__item {
	white-space: nowrap;;
	margin: 0px 15px 10px 15px;
}

.rrule__frequency__row__month-day-select {
	width: 150px;
}

.rrule__frequency__pills {
	display: flex;
	flex-flow: row wrap;
	
	margin-left: 15px;
}

.rrule__frequency__pills__item {
	width: fit-content;
	margin-right: 6px;
	margin-bottom: 6px;
	padding: 6px 15px 4px 15px;
	color: #333;
	cursor: pointer;
	border-radius: 10px;
	font-size: 12px;
	background-color: transparent;
	border: 1px solid #60A9FF;
	box-shadow: 0 2px 20px -10px rgba(115, 115, 115, 0.5);
}

.rrule__frequency__pills__item--active {
	background-color: #60A9FF !important;
	color: white !important;
	transition: 0.2s;
	
}

.rrule__frequency__row__select-wrapper {
	width: 150px;
	margin-right: 10px;
}

.rrule__end {
	margin-top: 10px;
	
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.rrule__end__item {
	text-wrap: nowrap;
	font-size: 14px;
	margin: 0px 15px 10px 15px;
}

.rrule__end__end-type-select {
	width: 100px;
}

.rrule__end__end-date {
	width: 200px !important;
	margin: 0px 15px 15px 15px;
}