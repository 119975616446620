/* wcc == web-chat-config */

.wcc {
	display: flex;
	flex-flow: row wrap-reverse;
	align-items: flex-end;
	justify-content: flex-start;

	margin: 20px 30px;
	width: calc(100% - 60px);
	min-width: 400px;
}

.wcc__options {
	margin-right: 50px;
	max-width: 500px;
	min-width: 400px;
}

.wcc__options__field {
	font-size: 14px;
	height: 120px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	border-bottom: 1px solid #f5f5f5;
	width: 100%;
}

.wcc__options__field--tall {
	margin-top: 30px;
	height: 550px !important;
	align-items: flex-start;
}

.wcc__options__field--large {
	margin-top: 30px;
	height: 300px !important;
	align-items: flex-start;
}

.wcc__options__field--medium {
	margin-top: 30px;
	height: 150px !important;
	align-items: flex-start;
}

.wcc__options__field--large-heading {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 100%;
	height: auto;
	padding-top: 30px;
	padding-bottom: 30px;
}

.wcc__options__field__label {
	flex: 2;
	margin-right: 20px;
}

.wcc__options__field__label__title {
	font-weight: 900;
}

.wcc__options__field__label__title__language_container {
	padding-left: 12px;
	display: inline-block;
}

.wcc__options__field__label__description {
	margin-top: 5px;
}

.wcc__options__field__colour__swatch {
	position: relative;
	padding: 5px;
	background: #fff;
	border-radius: 1px;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
	display: inline-block;
	cursor: pointer;
}

.wcc__options__field__colour__box {
	width: 36px;
	height: 14px;
	border-radius: 2px;
}

.wcc__options__field__colour__popover {
	position: absolute;
	z-index: 2;
}

.wcc__options__field__colour__cover {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
}

.wcc__options__field__input {
	flex: 1;
	margin-left: auto;

	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
}

.wcc__options__field__input__label {
	font-size: 11px;
	margin-top: 10px;
	margin-bottom: 2px;
	color: #7e7e7e;
}

.wcc__options__field__input__tooltip-length {
	margin-top: 10px;
	font-size: 12px;
}

.wcc__options__field__input__tooltip-length--red {
	color: red;
}

.wcc__options__field__avatar {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.wcc__options__field__avatar__image {
	border-radius: 50px;
	height: 50px;
	width: 50px;
	margin-right: 10px;
}

.wcc__preview {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-end;
	margin-right: 20px;
	min-width: 700px;
	position: sticky;
	top: 30px;
	/*user-select: none;*/
}

@media (max-width: 1780px) {
	.wcc__preview {
		position: relative !important;
	}
}

@media (max-width: 1517px) {
	.wcc__preview {
		position: relative !important;
	}
}

.wcc__preview__update {
	margin-top: auto;
	display: flex;
	flex-flow: row nowrap;
}

.wcc__preview__core {
	background-color: rgb(244, 246, 249);
	width: 100%;
	margin-bottom: 30px;
	display: flex;
	flex-direction: column;
	position: relative;
	padding: 15px 15px 15px;
	border-radius: 8px;
	/*pointer-events: none;*/
}

.wcc__preview__core__header {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	font-weight: 500;
	font-size: 13px;
	color: rgb(135, 135, 135);
	margin-bottom: 15px;
}

.wcc__preview__core__header svg {
	padding-right: 5px;
}

.wcc__preview__iframe {
	height: 500px;
}

.wcc__preview__iframe * {
	font-family: demandhub-montserrat-regular, sans-serif !important;
}

/* 

STYLES FOR WEB CHAT PREVIEW 
Taken copy and paste to make
maintainability easy peasy

*/

#dh-webchat-bubble-iframe .bubble {
	box-sizing: border-box;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;

	position: absolute;
	height: 54px;
	/* min-width: 48px; */
	/* min-width: 120px; */
	padding: 0 20px;

	font-size: 17px;
	border-radius: 40px;
	z-index: 1000010;

	user-select: none;

	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

#dh-webchat-bubble-iframe .bubble--no-text {
	height: 54px !important;
	width: 54px !important;
	padding: 0px !important;
}

#dh-webchat-bubble-iframe .bubble--close {
	background-color: rgb(252, 102, 102) !important;
}

#dh-webchat-bubble-iframe .bubble:hover {
	cursor: pointer;
	transition: all 0.1s ease-in-out;
	box-shadow: 0 10px 10px rgba(0, 0, 0, 0.15);
}

#dh-webchat-bubble-iframe .bubble__icon_container {
	display: flex;
	align-content: center;
	justify-content: center;
}

#dh-webchat-bubble-iframe .bubble__text {
	font-weight: 900;
	padding-left: 10px;
	padding-bottom: 1px;

	max-width: 200px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

#dh-webchat-tooltip-iframe .tooltip {
	width: 350px;
	height: 200px;
}

#dh-webchat-tooltip-iframe .tooltip__container {
	position: absolute;
	width: 290px;
	height: 80px;
	background: white;
	border-radius: 15px;
	z-index: 100005;
	cursor: pointer;
}

#dh-webchat-tooltip-iframe .tooltip__container--bottom-right {
	bottom: 30px;
	right: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__container--bottom {
	bottom: 30px;
	right: calc(50% - 145px);
}

#dh-webchat-tooltip-iframe .tooltip__container--bottom-left {
	bottom: 30px;
	left: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__container--left {
	bottom: calc(50% - 40px);
	left: 20px;
}

#dh-webchat-tooltip-iframe .tooltip__container--top-left {
	top: 30px;
	left: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__container--top {
	top: 30px;
	right: calc(50% - 145px);
}

#dh-webchat-tooltip-iframe .tooltip__container--top-right {
	top: 30px;
	right: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__container--right {
	bottom: calc(50% - 40px);
	right: 20px;
}

#dh-webchat-tooltip-iframe .tooltip__container__content {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	height: 78px;
	width: 270px;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
}

#dh-webchat-tooltip-iframe .tooltip__container__content__profile > img {
	border-radius: 50px !important;
	height: 50px !important;
	width: 50px !important;
	max-width: initial !important;
	cursor: pointer;
}

#dh-webchat-tooltip-iframe .tooltip__container__content__message {
	font-size: 16px;
	color: #404040;
	margin-left: 10px;
	cursor: pointer;
	line-height: 1.4;
	font-weight: 600;

	/* XXX Hack to make it exactly like production */
	min-width: 210px;
}

#dh-webchat-tooltip-iframe .tooltip__arrow {
	position: absolute;

	height: 40px;
	width: 40px;
	box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
	background: white;
	border-radius: 5px;
	transform: rotate(45deg);
	z-index: 100004;
	cursor: pointer;
}

#dh-webchat-tooltip-iframe .tooltip__arrow--bottom-right {
	bottom: 23px;
	right: 30px;
}

#dh-webchat-tooltip-iframe .tooltip__arrow--bottom {
	bottom: 23px;
	right: calc(50% - 20px);
}

#dh-webchat-tooltip-iframe .tooltip__arrow--bottom-left {
	bottom: 23px;
	left: 30px;
}

#dh-webchat-tooltip-iframe .tooltip__arrow--left {
	bottom: calc(50% - 20px);
	left: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__arrow--top-left {
	top: 23px;
	left: 30px;
}

#dh-webchat-tooltip-iframe .tooltip__arrow--top {
	top: 23px;
	right: calc(50% - 20px);
}

#dh-webchat-tooltip-iframe .tooltip__arrow--top-right {
	top: 23px;
	right: 30px;
}

#dh-webchat-tooltip-iframe .tooltip__arrow--right {
	bottom: calc(50% - 20px);
	right: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__layer {
	position: absolute;
	width: 290px;
	height: 80px;
	background: white;
	border-radius: 15px;
	box-shadow: 0 0px 20px rgba(0, 0, 0, 0.2);
	z-index: 100003;
	cursor: pointer;
}

#dh-webchat-tooltip-iframe .tooltip__layer--bottom-right {
	bottom: 30px;
	right: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__layer--bottom {
	bottom: 30px;
	right: calc(50% - 145px);
}

#dh-webchat-tooltip-iframe .tooltip__layer--bottom-left {
	bottom: 30px;
	left: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__layer--left {
	bottom: calc(50% - 40px);
	left: 20px;
}

#dh-webchat-tooltip-iframe .tooltip__layer--top-left {
	top: 30px;
	left: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__layer--top {
	top: 30px;
	right: calc(50% - 145px);
}

#dh-webchat-tooltip-iframe .tooltip__layer--top-right {
	top: 30px;
	right: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__layer--right {
	bottom: calc(50% - 40px);
	right: 20px;
}

#dh-webchat-tooltip-iframe .tooltip__close {
	position: absolute;
	color: #5c5c5c;
	align-self: flex-start;
	cursor: pointer;
	font-size: 12px;
	background-color: transparent;
	z-index: 100000000000000;
}

#dh-webchat-tooltip-iframe .tooltip__close--bottom-right {
	right: 10px;
	bottom: 120px;
}

#dh-webchat-tooltip-iframe .tooltip__close--bottom {
	bottom: 120px;
	right: 30px;
}

#dh-webchat-tooltip-iframe .tooltip__close--bottom-left {
	bottom: 120px;
	left: 10px;
}

#dh-webchat-tooltip-iframe .tooltip__close--left {
	right: 40px;
	bottom: 150px;
}

#dh-webchat-tooltip-iframe .tooltip__close--top-left {
	left: 10px;
	bottom: 65px;
}

#dh-webchat-tooltip-iframe .tooltip__close--top {
	bottom: 65px;
	right: 30px;
}

#dh-webchat-tooltip-iframe .tooltip__close--top-right {
	right: 10px;
	bottom: 65px;
}

#dh-webchat-tooltip-iframe .tooltip__close--right {
	right: 20px;
	bottom: 150px;
}

#dh-webchat-tooltip-iframe .tooltip__close > span {
	background-color: #fff;
	padding: 7px 13px 7px 13px;
	border-radius: 10px;
}
