.mb-edit-contact-name {
}

.mb-edit-contact-name-text {
}

.mb-edit-contact-name-text:hover {
    cursor: pointer;
    opacity: 0.8;
}

.mb-edit-contact-name-edit {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
}

.mb-edit-contact-name-input {
    margin-top: 3px;
    font-size: 18px;
    border: none;
    border-bottom: 1px solid #B6B6B6;
    min-width: 10px;
}

.mb-edit-contact-name-input::placeholder {
    color: #B6B6B6;
}

.mb-edit-contact-button {
    margin-left: 7px;
    font-size: 14px;
    padding: 6px 10px 4px 10px;
    border-radius: 50px;
    align-self: flex-end;
}

.mb-edit-contact-button--disabled {
    opacity: 0.5;
}

.mb-edit-contact-button--success {
    border: 2px solid #60A9FF;
}

.mb-edit-contact-button--cancel {
    border: 2px solid #e74c3c;
}

.mb-edit-contact-button--success:hover {
    border: 2px solid #60A9FF;
    background-color: #60A9FF;
    color: white;
    cursor: pointer;
}

.mb-edit-contact-button--cancel:hover {
    border: 2px solid #e74c3c;
    background-color: #e74c3c;
    color: white;
    cursor: pointer;
}
