.toggle {
	font-size: 14px;
	height: 100px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.toggle__label {
	flex: 1;
	margin-right: 20px;
}

.toggle__label__title {
	font-weight: 900;
}

.toggle__label__description {
	margin-top: 5px;
}
