.companies__buttons--header-align {
	margin-top: 18px;
	margin-left: auto;
	text-align: right;
}
.companies__container--header {
	display: flex;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-ms-flex-line-pack: justify;
	align-content: space-between;
}
.companies__name {
	display: flex;
	align-items: center;
}
.companies__actions {
	display: flex;
}
.companies__actions div {
	margin-right: 5px;
}
.companies__search {
	margin: 0 30px 30px 30px;
	display: flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
}
@media (max-width: 767px) {
	.companies__table--padding {
		padding: 0px 0px 13px;
	}
}

.manage-company__form {
	display: flex;
	flex-direction: column;
	margin-left: 40px;
	font-size: 18px;
	width: 400px;
}

.manage-company__form__group {
	margin-bottom: 15px;
}

.manage-company__form__buttons {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
